import { Analysis }        from './Analysis.js'
import { GoldenRectangle } from './GoldenRectangle.js'
import { ScalarImage }     from './image/index.js'
import { chordTriads }     from './musicDefinitions/index.js'
import { indexOfMaxBy }    from './util.js'
import { octaveRel }       from './Scale.js'

const FOCUS_CENTROID = false

const MELODY_RANGE = 12
const MELODY_OFFSET = -3

class Measure {
  constructor ({ segments }) {
    this.segments = segments
  }

  calcEnergies ({ energyMap, background }) {
    this.maxEnergy = -1
    this.minEnergy = 1000
    const nonzero = (n) => n !== 0 ? n : 1000
    this.energies = this.segments.map(({ inner, outer }) => {
      const { val: innerEnergy, pt: innerPt } = energyMap.dominantInTriangle(inner.v0, inner.v1, inner.v2, { tolerance: 20, background })
      const { val: outerEnergy, pt: outerPt } = energyMap.dominantInTriangle(outer.v0, outer.v1, outer.v2, { tolerance: 20, background })
      const diff = outerEnergy - innerEnergy
      this.maxEnergy = Math.max(this.maxEnergy, innerEnergy, outerEnergy)
      this.minEnergy = Math.min(this.minEnergy, nonzero(innerEnergy), nonzero(outerEnergy))
      return { innerEnergy, innerPt, outerEnergy, outerPt, diff }
    })
  }

  calcNoteSpecs ({ energyToDegree, getColor, xform2world }) {
    const iMax = indexOfMaxBy(this.energies, e => e.diff)
    this.noteSpecs = this.energies.map(({ innerEnergy, innerPt, outerEnergy, outerPt, diff }, i) => {
      const downbeat = i === 0
      const useOuter = (i === iMax && this.energies[i].diff > 0)
      const energy = useOuter ? outerEnergy : innerEnergy
      const degree = energy === 0 ? null : energyToDegree({ energy })
      const v = (energy === 0)
        ? { x: (innerPt.x + outerPt.x) / 2, y: (innerPt.y + outerPt.y) / 2 }
        : useOuter ? outerPt : innerPt
      return { degree, downbeat, passingNote: useOuter && !downbeat, color: getColor(v), coords: xform2world(v) }
    })
    if (this.noteSpecs.every(spec => spec.degree == null)) {
      this.noteSpecs[0].degree = 0
      this.noteSpecs[1].degree = 0
    }
  }
}

export function composeSpiral ({ image, song, config, progression, slow }) {
  const scale = 0.25

  // console.log({ imagewidth: image.width, imageheight: image.height })

  const m = image.scaled({ scale }).toLab()

  const { trimmed, offset } = m.trimConstantEdges({ minWidth: 100, minHeight: 80 })
  // console.log({ offset, trimmed })
  const xform2world = ({ x, y }) => ({ x: image.origin.x + (offset.x + x) / scale, y: image.origin.y + (offset.y + y) / scale })

  const energyMap = getEnergyMap(trimmed)
  const { val: background } = energyMap.dominantValue()

  // console.log({ background })

  const focus = findFocus({ energyMap })
  // console.log({ energyMap, focus })
  const gold = GoldenRectangle.fitTo({ width: energyMap.width, height: energyMap.height, focus })

  const nMeasures = 8
  const elements = gold.subdivide({ n: nMeasures })
  const notesPerMeasure = slow ? 8 : 16
  const measures = elements.map(element => new Measure({ segments: element.segments(notesPerMeasure) }))

  measures.forEach(measure => measure.calcEnergies({ energyMap, background }))

  const minEnergy = Math.min(...measures.map(measure => measure.minEnergy))
  const maxEnergy = Math.max(...measures.map(measure => measure.maxEnergy))

  const energyToDegree = ({ energy }) => maxEnergy === minEnergy ? 0 : Math.round(MELODY_RANGE * (energy - minEnergy) / (maxEnergy - minEnergy)  + MELODY_OFFSET)

  measures.forEach(measure => measure.calcNoteSpecs({ energyToDegree, getColor: v => image.getRgb(v.x, v.y), xform2world }))

  // console.log({ measures })

  const specs = measures.flatMap((measure, i) => measure.noteSpecs.map(spec => ({ ...spec, chord: progression[i] })))

  finalizeNotes({ specs, song, notesPerMeasure, progression })
}

function finalizeNotes ({ specs, song, notesPerMeasure, progression }) {
  specs.forEach((spec, i) => {
    const { chord, passingNote, degree } = spec
    if (!passingNote) spec.degree = nearestChordal(degree, chord)
  })
  specs.forEach((spec, i) => {
    const { chord, passingNote, degree } = spec
    if (passingNote) {
      const prev = specs[i - 1]
      const next = specs[i + 1]
      if (prev == null || next == null) {
        spec.degree = null
      } else {
        spec.degree = nearestNonChordal(Math.round((prev.degree + next.degree) / 2), degree, chord)
      }
    }
  })

  const db = notesPerMeasure === 16 ? 1 : 2
  let lastDegree = null
  for (let i = 0; i < specs.length; i++) {
    let sixteenths = db
    const prev = specs[i - 1]
    const spec = specs[i]
    const next = specs[i + 1]
    const next2 = specs[i + 2]
    const next3 = specs[i + 3]
    const chord = spec.downbeat ? spec.chord : null
    let degree = spec.degree
    if (((i % 4) % 2 === 0) && next && !next.downbeat && next.degree === degree) {
      // merge repeated notes, but not across beat boundaries
      sixteenths += db
      i++
      if (next2 && next3 && !next2.downbeat && !next3.downbeat && next2.degree === degree && next3.degree === degree) {
        // merge next two notes too if not crossing downbeat
        sixteenths += 2 * db
        i += 2
      }
    } else if (prev && prev.degree === degree) {
      // insert a rest for repeated note that can't be merged
      degree = null
    }
    song.melody.addNote({ duration: `${16 / sixteenths}n`, degree, chord, vis: { color: spec.color, coords: spec.coords } })
    if (degree !== null) lastDegree = degree
  }

  const chord = progression[progression.length - 1]
  song.melody.addNote({ duration: '2n', degree: nearestTonic(lastDegree, chord), chord })
  // song.melody.addNote({ duration: '4n', degree: null })
  // song.melody.addNote({ duration: '4n', degree: null })
}

function isChordal (degree, chord) {
  const { rel } = octaveRel(degree)
  const triad = chordTriads[chord]
  return triad.some(d => d === rel)
}

function isTonic (degree, chord) {
  const { rel } = octaveRel(degree)
  const triad = chordTriads[chord]
  return rel === triad[0]
}

function nearestChordal (degree, chord) {
  if (degree == null) return null
  for (let i = 0; true; i++) {
    if (isChordal(degree + i, chord)) return degree + i
    if (isChordal(degree - i, chord)) return degree - i
  }
}

function nearestTonic (degree, chord) {
  if (degree == null) return null
  for (let i = 0; true; i++) {
    if (isTonic(degree + i, chord)) return degree + i
    if (isTonic(degree - i, chord)) return degree - i
  }
}

function nearestNonChordal (degree, nominal, chord) {
  const { rel } = octaveRel(degree)
  const triad = chordTriads[chord]
  if (triad.some(d => d === rel)) {
    return nominal > degree ? degree + 1 : degree - 1
  }
  return degree
}

function getEnergyMap (labImage) {
  const energyMap = new ScalarImage({ width: labImage.width, height: labImage.height })
  labImage.forEachLab((x, y, lab) => {
    const analysis = new Analysis({ intensity: lab[0] / 100, edginess: labImage.getEdginess(x, y) })
    const energy = 255 * analysis.energy
    energyMap.setValue(x, y, energy)
  })
  return energyMap
}

function getEnergyMapRGB (image) {      /* eslint-disable-line no-unused-vars */
  const energyMap = new ScalarImage({ width: image.width, height: image.height })
  image.forEachColor((x, y, color) => {
    const v = color.value()
    const analysis = new Analysis({ intensity: v / 100, edginess: image.getEdginess(x, y) })
    const energy = 255 * analysis.energy
    energyMap.setValue(x, y, energy)
  })
  return energyMap
}

function findFocus ({ energyMap }) {
  const scale = 4
  const margin = 0.1 * Math.min(energyMap.width, energyMap.height) / scale
  const pt = FOCUS_CENTROID
    ? energyMap.downSize(scale).centroid({ margin })
    : energyMap.downSize(scale).maxInImage({ margin }).pt
  return { x: scale * (pt.x + 0.5), y: scale * (pt.y + 0.5) }
}
