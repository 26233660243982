function init (el, isShowing) {
  if (isShowing) {
    show(el)
  } else {
    hide(el)
  }
}

function show (el) {
  el.classList.remove('us-hidden')
  el.classList.add('us-visible')
}

function hide (el) {
  el.classList.add('us-hidden')
  el.classList.remove('us-visible')
}

export default { init, show, hide }
