import { zip } from './_snowpack/pkg/fflate.js'

/* To import from the bundled files, import both the css and the js */
// import '../unspoken-symphony/dist/unspoken-symphony-3.0.1.css'
// import * as UnspokenSymphony from '../unspoken-symphony/dist/unspoken-symphony-3.0.1'

/* To import from the source, no need to import the css */
import * as UnspokenSymphony from './unspoken-symphony/index.js'

import './css/style.css'
import instruments from './instruments.json'

const samplesRootUrl = '/samples'

window.onload = () => {
  UnspokenSymphony.setInstruments({ samplesRootUrl, instruments })

  let flipped = false
  let rotate = 0

  let creator = null

  // add UI event listeners
  document.getElementById('melody-button').onclick = () => {
  //  Tone.start()
  //  Tone.context = Tone.context._context._nativeAudioContext

    creator.generateMelody()
    document.getElementById('edit-buttons').classList.add('hidden')
  }

  const cropButton = document.getElementById('crop-button')

  function showCrop () {
    cropButton.classList.add('selected')
    creator?.enableCrop(true)
  }

  function hideCrop () {
    cropButton.classList.remove('selected')
    creator?.enableCrop(false)
  }

  cropButton.addEventListener('click', () => {
    const el = document.getElementById('crop-button')
    const toggleState = el.classList.contains('selected')
    if (toggleState) {
      hideCrop()
    } else {
      showCrop()
    }
  })

  function forEachSliderButton (func) {
    ['zoom', 'brighten', 'contrast'].forEach(type => func(document.getElementById(`${type}-button`), type))
  }

  forEachSliderButton((el, type) => el.addEventListener('click', () => toggleSlider(document.getElementById(`${type}-button`), type)))

  function toggleSlider (el, selector) {
    const toggleState = el.classList.contains('selected')
    hideSliders()
    if (toggleState !== true) {
      document.getElementById(`${selector}-slider`)?.classList?.toggle('hidden')
      el.classList.add('selected')
    }
  }

  function hideSliders () {
    const sliders = document.getElementsByClassName('edit-slider')
    for (const slider of sliders) {
      slider.classList.add('hidden')
    }
    forEachSliderButton(el => el.classList.remove('selected'))
  }

  document.getElementById('reset-button').addEventListener('click', () => {
    creator?.resetEdits()
    hideSliders()
    hideCrop()
  })

  document.getElementById('brighten-slider').oninput = function (e) {
    creator.setBrightness(e.target.value)
  }

  document.getElementById('contrast-slider').oninput = function (e) {
    creator.setContrast(e.target.value)
  }

  document.getElementById('zoom-slider').oninput = function (e) {
    creator.setZoom(e.target.value)
  }

  // Reset the UI and unspoken symphony component
  const reset = function () {
    // reset ToneJS
    Tone.Transport.cancel()

    // reset UI elements
    document.getElementById('container').innerHTML = ''
    document.getElementById('container').classList.remove('croppie-container')
    document.getElementById('edit-buttons').classList.add('hidden')

    // restart creator element
    creator = new UnspokenSymphony.Creator({
      parent: document.getElementById('container'),
      width:  800,
      height: 600
    })

    // when user has uploaded an image, update UI to show editing controls
    creator.on('image loaded', () => {
      // console.log('image loaded')
      creator.enableEditing({
        onZoom: val => (document.getElementById('zoom-slider').value = val)
      })

      document.getElementById('edit-buttons').classList.remove('hidden')
      document.getElementById('instrument-selectors').classList.remove('hidden')

      // ui handlers
      hideSliders()
      document.getElementById('rotate-button').onclick = () => {
        rotate += 90
        creator.setRotate(rotate)
      }
      document.getElementById('flip-button').onclick = () => {
        flipped = !flipped
        creator.setFlip(flipped)
      }
    })

    creator.on('started playing', () => {
      document.getElementById('instrument-selectors').classList.add('hidden')
    })

    creator.on('finished playing', () => {
      document.getElementById('file-buttons').classList.remove('hidden')
      document.getElementById('instrument-selectors').classList.remove('hidden')
    })

    const melodyInstrumentSelector = document.getElementById('select-melody-instrument')
    const accompanimentInstrumentSelector = document.getElementById('select-accompaniment-instrument')
    setupSelector(melodyInstrumentSelector, instruments, instrument => creator.setMelodyInstrument(instrument))
    setupSelector(accompanimentInstrumentSelector, instruments, instrument => creator.setAccompanimentInstrument(instrument))
  }

  // initialize component
  reset()

  document.getElementById('file-button').onclick = () => {
    document.getElementById('wait').classList.remove('hidden')
    const { width, height, origin } = creator.getImageDimensions()
    console.log({ width, height, origin })
    creator.getImage((img) => {
      creator.getMP3((mp3, error) => {
        creator.getPDF({
          backgroundImage: './assets/us_music-sheet_051320a.png',
          font:            './assets/Omnes.ttf',
          songName:        'My title',
          author:          'my name'
        }, (pdf, error) => {
          creator.getAnimationSequence({ format: 'png', renderBackground: false, quality: 20, framerate: 20, verbose: true }, async (frames, err) => {
            const zipContent = {
              'base.jpg':              await blob2U8(img),
              'audio.mp3':             await blob2U8(mp3),
              'Unspoken-Symphony.pdf': await blob2U8(pdf),
              'frames.tar':            await blob2U8(frames)
            }
            zip(zipContent, { level: 0 }, (err, data) => {
              if (err) { throw err }
              const blob = new Blob([data], { type: 'application/zip' })
              document.getElementById('wait').innerHTML = 'Thank you :]'
              downloadFile(blob, 'files.zip')
            })
          })
        })
      })
    })
  }
}

async function blob2U8 (blob) {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(new Uint8Array(reader.result))
    reader.readAsArrayBuffer(blob)
  })
}

function downloadFile (blob, name) {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  a.href = url
  a.download = name
  a.click()
  setTimeout(() => {
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }, 0)
}

function setupSelector (selector, instruments, callback) {
  let initialValue = null
  const keys = Object.keys(instruments)
  keys.sort((a, b) => instruments[a].name < instruments[b].name ? -1 : instruments[a].name === instruments[b].name ? 1 : 0)
  keys.forEach((key) => {
    const instrument = instruments[key]
    const option = document.createElement('option')
    option.setAttribute('value', key)
    option.innerText = instrument.name
    selector.appendChild(option)
    if (instrument.default) {
      initialValue = key
    }
  })
  selector.onchange = () => callback(selector.value)
  if (initialValue) {
    selector.value = initialValue
    callback(selector.value)
  }
}
