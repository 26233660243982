// not truly golden since we allow arbitrary dimensions, but follows same
// hierarchical structure

import { range } from './util.js'

const PHI0 = 0.618
const FOC = 0.724

const PI = Math.PI
const PI_2 = PI / 2

class Triangle {
  constructor (v0, v1, v2) {
    this.v0 = v0
    this.v1 = v1
    this.v2 = v2
  }

  get centroid () {
    return {
      x: Math.round((this.v0.x + this.v1.x + this.v2.x) / 3),
      y: Math.round((this.v0.y + this.v1.y + this.v2.y) / 3)
    }
  }
}

class Element {
  constructor ({ x, y, width, height, focusRight, focusBottom, clockwise }) {
    const x0 = this.x0 = x
    const y0 = this.y0 = y
    const x1 = this.x1 = x + width
    const y1 = this.y1 = y + height
    this.width = width
    this.height = height
    this.vo = {
      x: focusRight ? x1 : x0,
      y: focusBottom ? y1 : y0
    }

    this.clockwise = clockwise
    const focusLeft = !focusRight
    const focusTop = !focusBottom
    let angles
    if (focusRight && focusTop) {
      angles = [PI_2, PI]
    } else if (focusRight && focusBottom) {
      angles = [PI, 3 * PI_2]
    } else if (focusLeft && focusTop) {
      angles = [0, PI_2]
    } else if (focusLeft && focusBottom) {
      angles = [3 * PI_2, 2 * PI]
    }
    this.theta0 = clockwise ? angles[1] : angles[0]
    this.theta1 = clockwise ? angles[0] : angles[1]
  }

  segments (n = 4) {
    const vo = this.vo
    const vf = {
      x: (vo.x === this.x0) ? this.x1 : this.x0,
      y: (vo.y === this.y0) ? this.y1 : this.y0
    }
    const arc = range(n + 1).map((i) => {
      const alpha = i / n
      const theta = (1 - alpha) * this.theta0 + alpha * this.theta1
      return { x: Math.round(vo.x + this.width * Math.cos(theta)), y: Math.round(vo.y + this.height * Math.sin(theta)) }
    })
    return range(n).map(i => ({ inner: new Triangle(vo, arc[i], arc[i + 1]), outer: new Triangle(vf, arc[i], arc[i + 1]) }))
  }
}

export class GoldenRectangle {
  constructor ({ x = 0, y = 0, width, height, horizontal, forward = true, clockwise = true }) {
    this.x = x
    this.y = y
    this.width = width
    this.height = height
    this.horizontal = horizontal ?? (width > height)
    this.forward = forward
    this.clockwise = clockwise
  }

  get base () {
    const clockwise = this.clockwise
    const forward = this.forward
    if (this.horizontal) {
      const width = Math.round(this.width * PHI0)
      const height = this.height
      const x = forward ? this.x : this.x + (this.width - width)
      const y = this.y
      const focusRight = forward
      const focusBottom = (clockwise === forward)
      return new Element({ x, y, width, height, focusRight, focusBottom, clockwise })
    } else {
      const width = this.width
      const height = Math.round(this.height * PHI0)
      const x = this.x
      const y = forward ? this.y : this.y + (this.height - height)
      const focusBottom = forward
      const focusRight = (clockwise !== forward)
      return new Element({ x, y, width, height, focusRight, focusBottom, clockwise })
    }
  }

  get subrect () {
    const clockwise = this.clockwise
    if (this.horizontal) {
      const horizontal = false
      const width = Math.round((1 - PHI0) * this.width)
      const height = this.height
      const x = this.forward ? this.x + this.width - width : this.x
      const y = this.y
      const forward = (clockwise === this.forward)
      return new GoldenRectangle({ x, y, width, height, horizontal, forward, clockwise })
    } else {
      const horizontal = true
      const width = this.width
      const height = Math.round((1 - PHI0) * this.height)
      const x = this.x
      const y = this.forward ? this.y + this.height - height : this.y
      const forward = (clockwise !== this.forward)
      return new GoldenRectangle({ x, y, width, height, horizontal, forward, clockwise })
    }
  }

  subdivide ({ n }) {
    return n === 0 ? [] : this.subrect.subdivide({ n: n - 1 }).concat([this.base])
  }

  static fitTo ({ width, height, focus }) {
    const dx0 = focus.x
    const dx1 = width - dx0
    const dy0 = focus.y
    const dy1 = height - dy0

    const hforward = (dx0 > dx1)
    const vforward = (dy0 > dy1)

    const a = hforward ? Math.min(dx0 / FOC, dx1 / (1 - FOC), width) : Math.min(dx0 / (1 - FOC), dx1 / FOC, width)
    const b = vforward ? Math.min(dy0 / FOC, dy1 / (1 - FOC), height) : Math.min(dy0 / (1 - FOC), dy1 / FOC, height)

    const x = Math.round(hforward ? focus.x - FOC * a : focus.x - (1 - FOC) * a)
    const y = Math.round(vforward ? focus.y - FOC * b : focus.y - (1 - FOC) * b)

    const horizontal = a > b

    const forward = horizontal ? hforward : vforward
    const clockwise = horizontal === (hforward === vforward)

    // console.log({ width, height, focus, margin })
    // console.log({ x, y, width: Math.round(a), height: Math.round(b), horizontal, forward, clockwise })
    const rect = new GoldenRectangle({ x, y, width: Math.round(a), height: Math.round(b), horizontal, forward, clockwise })
    // console.log(rect, 'focus', focus.x, focus.y, 'result', ...Object.values(rect.subrect.subrect.subrect.subrect.subrect.subrect.subrect.subrect.base.vo))
    return rect
  }
}
