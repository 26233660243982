import { TriangleRasterizer } from './raster.js'
import { range } from '../util.js'

export class ImageBase {
  constructor ({ width, height, channels, array, origin }) {
    this.width = width
    this.height = height
    this.channels = channels
    this.array = array ?? new Uint32Array(width * height * channels)
    this.origin = origin ?? { x: 0, y: 0 }
  }

  get halfWidth () { return this.width / 2 }
  get halfHeight () { return this.height / 2 }
  get right () { return this.width - 1 }
  get bottom () { return this.height - 1 }

  getIndex (x, y) {
    return Math.round((y * this.width + x) * this.channels)
  }

  getValues (x, y) {
    const i = this.getIndex(x, y)
    return [...this.array.slice(i, i + this.channels)]
  }

  forEachIndex (func) {
    const width = this.width
    const height = this.height
    const channels = this.channels
    let i = 0
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        func(x, y, i)
        i += channels
      }
    }
  }

  trim ({ topTrim, bottomTrim, leftTrim, rightTrim, minWidth, minHeight }) {
    if (topTrim === 0 && bottomTrim === 0 && leftTrim === 0 && rightTrim === 0) return this

    const trimmed = new this.constructor({
      width:    this.width - leftTrim - rightTrim,
      height:   this.height - topTrim - bottomTrim,
      channels: this.channels
    })
    let i = 0
    for (let y = 0; y < trimmed.height; y++) {
      for (let x = 0; x < trimmed.width; x++) {
        const j = this.getIndex(x + leftTrim, y + topTrim)
        for (let c = 0; c < trimmed.channels; c++) {
          trimmed.array[i + c] = this.array[j + c]
        }
        i += trimmed.channels
      }
    }

    return trimmed
  }

  trimConstantEdges ({ minWidth, minHeight, tolerance = 1 }) {
    const differ = (i, j) => range(this.channels).some(c => Math.abs(this.array[i + c] - this.array[j + c]) > tolerance)
    const findFirstOuter = (nouter, ninner, pred) => {
      for (let outer = 0; outer < nouter; outer++) {
        for (let inner = 0; inner < ninner; inner++) {
          if (pred(outer, inner)) return outer
        }
      }
    }

    const width  = this.width
    const height = this.height

    minWidth  = Math.min(minWidth, width)
    minHeight = Math.min(minHeight, height)

    const topIndex    = this.getIndex(this.halfWidth, 0)
    const bottomIndex = this.getIndex(this.halfWidth, this.bottom)
    const leftIndex   = this.getIndex(0,              this.halfHeight)
    const rightIndex  = this.getIndex(this.right,     this.halfHeight)

    let topTrim    = findFirstOuter(height, width,  (y, x) => differ(topIndex,    this.getIndex(x,              y)))
    let bottomTrim = findFirstOuter(height, width,  (y, x) => differ(bottomIndex, this.getIndex(x,              this.bottom - y)))
    let leftTrim   = findFirstOuter(width,  height, (x, y) => differ(leftIndex,   this.getIndex(x,              y)))
    let rightTrim  = findFirstOuter(width,  height, (x, y) => differ(rightIndex,  this.getIndex(this.right - x, y)))

    if (leftTrim == null || leftTrim + rightTrim  > width)  { leftTrim = rightTrim  = this.halfWidth  - minWidth  / 2 }
    if (topTrim  == null || topTrim  + bottomTrim > height) { topTrim  = bottomTrim = this.halfHeight - minHeight / 2 }

    if (width - leftTrim - rightTrim < minWidth) {
      leftTrim -= Math.min(leftTrim, minWidth / 2)
      rightTrim = width - leftTrim - minWidth
    }

    if (height - topTrim - bottomTrim < minHeight) {
      topTrim -= Math.min(topTrim, minHeight / 2)
      bottomTrim = height - topTrim - minHeight
    }

    const trimmed = this.trim({ topTrim, bottomTrim, leftTrim, rightTrim })
    const offset = { x: leftTrim, y: topTrim }

    return { trimmed, offset }
  }

  forEachIndexInTriangle (v0, v1, v2, func) {
    const rasterizer = new TriangleRasterizer({
      getIndex: (x, y) => this.getIndex(x, y)
    })

    rasterizer.rasterize(v0, v1, v2, func)
  }

  // downsize by box filter
  downSize (scale) {
    const down = new this.constructor({
      width:    Math.round(this.width / scale),
      height:   Math.round(this.height / scale),
      channels: this.channels
    })
    const sx = (this.width / down.width)
    const sy = (this.height / down.height)
    down.forEachIndex((x, y, i) => {
      const cx = Math.round(x * sx)
      const cy = Math.round(y * sy)
      const sum = new Array(this.channels).fill(0)
      let ct = 0

      for (let upx = cx; upx < cx + sx; upx++) {
        for (let upy = cy; upy < cy + sy; upy++) {
          const i = this.getIndex(upx, upy)
          for (let c = 0; c < this.channels; c++) {
            sum[c] += this.array[i + c]
          }
          ct++
        }
      }
      const j = down.getIndex(x, y)
      for (let c = 0; c < this.channels; c++) {
        down.array[j + c] = sum[c] / ct
      }
    })
    // console.log({ this: this, down })
    return down
  }
}
