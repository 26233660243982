import PDFDocument from '../../_snowpack/pkg/pdfkit/js/pdfkit.standalone.js'
import blobStream  from '../../_snowpack/pkg/blob-stream.js'

// const DPI = 2

export default ({
    sheet,
    drawing,
    ppi = 150,
    backgroundImage = './assets/pdf_background.png',
    songName = '',
    font = './assets/Omnes.ttf',
    author = ''
  } = {}, callback) =>  {
    // create a document the same way as above
    const doc = new PDFDocument;
    console.log('creating pdf')
    // pipe the document to a blob
    const stream = doc.pipe(blobStream());

  // doc.fontSize(25).text('Here is some vector graphics...', 100, 80);
  const img = new Image()
  //  img.src = './assets/us_music-sheet_w_name-01.png'

  const DPI = ppi / 75

  img.src = backgroundImage


    img.addEventListener('load', () => {
      var c = document.createElement('canvas');
      c.width = 612*DPI
      c.height = 792*DPI
      var ctx = c.getContext('2d');
      ctx.drawImage(img, 0, 0, c.width, c.height);
      // ctx.drawImage(sheet, 34*DPI, 287*DPI, 549*DPI, 256*DPI);
      ctx.drawImage(sheet, 34*DPI, 287*DPI, 549*DPI, 300*DPI);
      // ctx.drawImage(sheet, 34*DPI, 287*DPI, sheet.width*DPI, sheet.height*DPI);
    //  ctx.drawImage(drawing, 264*DPI,62*DPI, 314*DPI, 200*DPI);
      // bottom right corner coords
      let imageX = 264 + 314
      let imageY = 62 + 200
      let imageWidth = 266
      let imageHeight = imageWidth * drawing.height/drawing.width
       ctx.drawImage(drawing, (imageX - imageWidth)*DPI,(imageY-imageHeight)*DPI, imageWidth*DPI, imageHeight*DPI);
       console.log('sheet', sheet.width, sheet.height)
      var base64String = c.toDataURL();
      doc.image(base64String, 0, 0, {width: 612})
    //  doc.font(font)
    // doc.addImage(img, null, 0, 0, 612)

    fetch(font)
      .then(response => response.arrayBuffer())
      .then(pdfFont => {
        doc.font(pdfFont)
        const textX = 290
        const textY = 197
        doc.fillColor('#444444').fontSize(24).text(songName, 0, textY, { align: 'right', width: textX})
        doc.fontSize(18).text('by '+author, 0, textY+28, { align: 'right', width: textX})
        doc.end()
      })
    })



    stream.on('finish', function() {
      callback(stream.toBlob('application/pdf'), null)
    });
  }
