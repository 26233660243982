// const Sound = require('./sound.js')
import easing from './lib/easingFunctions.js'
import loop from '../../_snowpack/pkg/raf-loop.js'

const colors = {
  yellow: {
    a: [244, 72, 15],
    b: [255, 230, 0]
  },
  purple: {
    a: [0, 34, 136],
    b: [171, 6, 151]
  },
  red: {
    a: [244, 4, 44],
    b: [255, 106, 0]
  },
  pink: {
    a: [251, 50, 225],
    b: [197, 146, 245]
  },
  green: {
    a: [10, 54, 94],
    b: [2, 99, 80]
  },
  blue: {
    a: [2, 19, 110],
    b: [41, 182, 181]
  }
}

class Visualizer {
  constructor ({ imgCanvas, visCtx, config }) {
    this.config = config
    this.imgCanvas = imgCanvas
    this.visCtx = visCtx
    this.renderBackground = false
  }

  clear ({ origin } = {}) {
    this.visCtx.clearRect(0, 0, this.visCtx.canvas.width, this.visCtx.canvas.height)
    this.visCtx.drawImage(this.imgCanvas, 0, 0)
    this.currNotes = []
    this.time = 0
    this.index = 0
    this.origin = origin ?? { x: 0, y: 0 }
  }

  setSong (song) {
    this.melody = song.melody
  }

  play (callback) {
    if (this.engine) this.engine.stop()
    this.engine = loop((dt) => {
      this.renderNext(dt, callback)
    }).start()
  }

  renderNext (dt) { // delta time in milliseconds
    let note = this.melody.getNote(this.index)

    if (this.time >= this.melody.lengthSeconds + 0.5) {
      if (this.engine) this.engine.stop()
      return true
    }
    this.time += dt / 1000
    if (note && this.time >= note.time && this.index < this.melody.noteCount) {
      if (note.degree != null && note.vis != null) {
        this.addNote({ index: note.degree - 1, vis: note.vis })
      }
      this.index++
      note = this.melody.getNote(this.index)
    }

    this._update(dt)
    return false
  }

  _update (dt) {
    const width = this.visCtx.canvas.width
    const height = this.visCtx.canvas.height
    this.visCtx.clearRect(0, 0, width, height)
    if (this.renderBackground === true) {
      this.visCtx.globalAlpha = 1
      this.visCtx.drawImage(this.imgCanvas, this.origin.x, this.origin.y, width, height, 0, 0, width, height)
    }
    this.currNotes.forEach((note) => {
      note.radius += this.config.circleGrowth.value / 100
      //  note.opacity -= dt/this.config.circleFade.value
      note.progress -= dt / this.config.circleFade.value

      if (isNaN(note.progress)) {
      //  console.warn('UNDEFINED progress', this.config.circleGrowth.value, this.config.circleFade.value, note.progress)
        note.progress = 0
      }
      note.progress = Math.max(0, note.progress)
      drawCircle(note, this.visCtx, this.config, this.origin)
    })
  }

  stop () {
    //  console.log('trigger end')
    this.engine.stop()
  }

  addNote ({ index, vis, radius = 10, color = [255, 100, 0] }) {
    this.currNotes.push({ x: vis.coords.x, y: vis.coords.y, radius: radius, color: vis.color, progress: 1, index })
  }
}

function lerp (c0, c1, progress) {
  return c0.map((channel, index) => channel * progress + c1[index] * (1 - progress))
}

function rgbColor (c0) {
  return `rgb(${c0[0]}, ${c0[1]}, ${c0[2]})`
}

function drawCircle ({ x, y, color, progress, index, radius }, ctx, config, origin) {
  x -= origin.x
  y -= origin.y

  const opacity = easing[config.fadeEasing.value](progress)
  // let opacity = 1
  let rad = radius + (config.circleGrowth.value) * (1 - progress)
  if (isNaN(rad)) {
    console.warn('UNDEFINED rad', config.circleGrowth.value, radius, progress)
    rad = 0
  }
  if (rad < 0) {
    console.warn('RAD less than 0', config.circleGrowth.value, radius, progress, this, config)
    rad = 0
  }
  const drawMethod = config.animationType.value
  let baseColor = null
  if (config.color.value === 'rainbow') {
    const colorArray = Object.keys(colors)
    baseColor = colorArray[index % colorArray.length]
  } else {
    baseColor = config.color.value
  }

  ctx.globalAlpha = opacity
  const c0 = colors[baseColor].a
  const c1 = colors[baseColor].b
  if (progress !== 0) {
    if (drawMethod === 'drawing') {
      ctx.fillStyle = rgbColor(color)
    } else if (drawMethod === 'gradient') {
      const grad = ctx.createRadialGradient(x, y, 5, x, y, rad)
      grad.addColorStop('0.0', rgbColor(c1))
      grad.addColorStop('1.0', rgbColor(c0))
      ctx.fillStyle = grad
    } else if (drawMethod === 'fade') {
      const lerpColor = lerp(c1, c0, progress)
      ctx.fillStyle = rgbColor(lerpColor)
    } else if (drawMethod === 'drawingGradient') {
      const grad = ctx.createRadialGradient(x, y, 5, x, y, rad)
      grad.addColorStop('0.0', rgbColor(color))
      grad.addColorStop('1.0', rgbColor(c0))
      ctx.fillStyle = grad
    } else if (drawMethod === 'drawingFade') {
      const lerpColor = lerp(c0, color, progress)
      ctx.fillStyle = rgbColor(lerpColor)
    } else if (drawMethod === 'drawingGradientReverse') {
      const grad = ctx.createRadialGradient(x, y, 5, x, y, rad)
      grad.addColorStop('0.0', rgbColor(c0))
      grad.addColorStop('1.0', rgbColor(color))
      ctx.fillStyle = grad
    }
    ctx.beginPath()
    ctx.arc(x, y, rad, 0, 2 * Math.PI)
    ctx.fill()
    ctx.closePath()
  }
}

export default Visualizer
