import { ImageBase }                from './ImageBase.js'
import { maxBy, meanPt, partition } from '../util.js'

export class ScalarImage extends ImageBase {
  constructor ({ width, height, array, origin }) {
    super({ width, height, channels: 1, array: array ?? new Float64Array(width * height) }, origin)
  }

  forEachValue (func) {
    this.forEachIndex((x, y, i) => func(x, y, this.array[i]))
  }

  forEachValueInTriangle (v0, v1, v2, func) {
    this.forEachIndexInTriangle(v0, v1, v2, (x, y, i) => func(x, y, this.array[i]))
  }

  getValue (x, y) {
    return this.array[this.getIndex(x, y)]
  }

  setValue (x, y, val) {
    this.array[this.getIndex(x, y)] = val
  }

  dominantValue ({ tolerance = 5 } = {}) {
    const hist = {}
    this.forEachValue((x, y, val) => {
      val = Math.round(val)
      for (let i = -tolerance; i <= tolerance; i++) {
        const tval = i + val
        if (hist[tval] == null) hist[tval] = 0
        hist[tval] += 1
      }
    })
    const max = maxBy(Object.keys(hist), key => hist[key])
    return  {
      val: max
    }
  }

  dominantInTriangle (v0, v1, v2, { tolerance = 2, background = null } = {}) {
    const hist = {}
    this.forEachValueInTriangle(v0, v1, v2, (x, y, val) => {
      val = Math.round(val)
      if (background == null || Math.abs(val - background) > tolerance) {
        for (let i = -tolerance; i <= tolerance; i++) {
          const tval = i + val
          if (hist[tval] == null) hist[tval] = []
          hist[tval].push({ x, y, val })
        }
      }
    })

    const max = maxBy(Object.keys(hist), key => hist[key].length)
    if (max == null) {
      return {
        val: background ?? 0,
        pt:  meanPt(v0, v1, v2)
      }
    } else {
      return  {
        val: max,
        pt:  maxClusterCentroid({ pts: hist[max] })
      }
    }
  }

  // Finds all points in the image whose value are within the given tolerance of the maximum,
  // returns the maximum value and the centroid of the largest cluster of such points.
  maxInTriangle (v0, v1, v2, { tolerance = 5 } = {}) {
    let max = -1
    this.forEachValueInTriangle(v0, v1, v2, (x, y, val) => {
      if (val > max) {
        max = val
      }
    })
    if (max < 0) return { val: 0, pt: v0 }
    const pts = []
    this.forEachValueInTriangle(v0, v1, v2, (x, y, val) => {
      if (max - val <= tolerance) {
        pts.push({ x, y, val })
      }
    })
    const pt = maxClusterCentroid({ pts })
    return {
      val: max,
      pt
    }
  }

  meanInTriangle (v0, v1, v2, { background, tolerance }) {
    let sum = 0
    let ct = 0
    this.forEachValueInTriangle(v0, v1, v2, (x, y, val) => {
      if (val != null && (background == null || Math.abs(val - background) > tolerance)) {
        sum += val
        ct += 1
      }
    })
    return {
      val: ct === 0 ? (background ?? 0) : sum / ct,
      pt:  meanPt(v0, v1, v2)
    }
  }

  // Finds all points in the image whose value are within the given tolerance of the maximum,
  // returns the maximum value and the centroid of the largest cluster of such points.
  maxInImage ({ margin = 0, tolerance = 5 } = {}) {
    let max = -1
    this.forEachValue((x, y, val) => {
      if (this._isInMargin({ x, y, margin })) {
        if (val > max) {
          max = val
        }
      }
    })
    const pts = []
    this.forEachValue((x, y, val) => {
      if (this._isInMargin({ x, y, margin })) {
        if (max - val <= tolerance) {
          pts.push({ x, y, val })
        }
      }
    })
    // console.log({ max, pts, this: this, p104x58: this.getValue(104, 58) })
    const pt = maxClusterCentroid({ pts })
    return {
      val: max,
      pt
    }
  }

  centroid ({ margin = 0 } = {}) {
    let sx = BigInt(0)
    let sy = BigInt(0)
    let t = BigInt(0)
    this.forEachValue((x, y, val) => {
      if (this._isInMargin({ x, y, margin })) {
        sx += BigInt(x * val)
        sy += BigInt(y * val)
        t += BigInt(val)
      }
    })
    return {
      x: Number(sx / t),
      y: Number(sy / t)
    }
  }

  _isInMargin ({ x, y, margin }) {
    return x >= margin && x <= this.right - margin && y >= margin && y <= this.bottom - margin
  }
}

// Given a list of {x,y} points, groups them into clusters where two points
// are in the same cluster iff the distance between them is less than the
// given radius.  Returns the centroid of the cluster that has the most
// points.
function maxClusterCentroid ({ pts, radius = 3 }) {
  const r2 = radius * radius
  let clusters = []
  pts.forEach(pt => {
    const { false: disjoint, true: overlap } = partition(clusters, cluster => cluster.some(qt => ptDist2(pt, qt) < r2))
    const merged = [pt].concat(overlap.flat())
    clusters = disjoint.concat([merged])
  })
  const cluster = maxBy(clusters, cluster => cluster.length)
  const sum = cluster.reduce((s, pt) => ({ x: s.x + pt.val * pt.x, y: s.y + pt.val * pt.y, val: s.val + pt.val }), { x: 0, y: 0, val: 0 })
  const pt = { x: Math.round(sum.x / sum.val), y: Math.round(sum.y / sum.val) }
  return pt
}

function ptDist2 (p, q) {
  const dx = p.x - q.x
  const dy = p.y - q.y
  return dx * dx + dy * dy
}
