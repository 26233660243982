/* global CCapture */

import { EventEmitter } from '../../_snowpack/pkg/events.js'

import { Composer } from './Composer.js'
import { createCanvas, getCtx, letterbox } from './canvas.js'
import Sound from './sound.js'
import Visualizer from './Visualizer.js'
import anim from './animation.js'
import generatePDF from './generate-pdf.js'

// const DEBUG = false

class ImagePlayer extends EventEmitter {
  constructor ({ width, height, parent, config, scale = 0.9 }) {
    super()

    this.imgCanvas = createCanvas(width, height, scale)
    this.visCanvas = createCanvas(width, height, scale)

    parent.appendChild(this.imgCanvas)

    this.config = config
    parent.appendChild(this.visCanvas)

    this.parent = parent
    this._createButtons(parent)

    this.sound = new Sound({ config: this.config })
  }

  setMelodyInstrument (instrument) {
    this.sound.setMelodyInstrument(instrument)
  }

  setAccompanimentInstrument (instrument) {
    this.sound.setAccompanimentInstrument(instrument)
  }

  setImage (img) {
    const { canvas, pane } = letterbox(img, this.imgCanvas.width, this.imgCanvas.height)
    getCtx(this.imgCanvas).drawImage(canvas, 0, 0)
    this.imagePane = pane

    const composer = new Composer({ imgCanvas: this.imgCanvas, config: this.config, pane })
    const bpm = composer.bpm
    const musicScale = composer.musicScale
    const song = composer.song
    this.sheetImg = composer.sheet.img

    this.visualizer = new Visualizer({
      imgCanvas: this.imgCanvas,
      visCtx:    getCtx(this.visCanvas),
      config:    this.config
    })

    this.visualizer.clear()
    this.showPlay()

    this.sound.setSong({ song, musicScale, bpm })
    this.visualizer.setSong(song)
  }

  showPlay () {
    anim.show(this.playButton)
  }

  getMP3 (callback) {
    // this.sound.startRecord(() => {
    //   console.log( 'ENDED')
    //   setTimeout(() => this.sound.endRecord(callback), 2500)
    // })
    callback(this.sound.mp3)
    //  setTimeout( () => this.sound.endRecord(), 5000)
  }

  playVideo () {
    this.visualizer.clear()
    this.visualizer.play(() => {

    })
  }

  playSong () {
    this.sound.playSong(() => {
      setTimeout(() => this.showReplay(), 2500)
    }, this.config)
  }

  async getImage (format = 'jpeg') {
    const { width, height, origin } = this.imagePane
    this.visCanvas.width = width
    this.visCanvas.height = height
    getCtx(this.visCanvas).drawImage(this.imgCanvas, origin.x, origin.y, width, height, 0, 0, width, height)
    const blob = await new Promise(resolve  => this.visCanvas.toBlob(resolve, `image/${format}`))
    this.visCanvas.width = this.imgCanvas.width
    this.visCanvas.height = this.imgCanvas.height
    return blob
  }

  getImagePane () {
    return this.imagePane
  }

  async play (timeToStart = 600) {
    this.emit('started playing')
    await this.sound.prepare()

    setTimeout(() => {
      this.playVideo()
      this.playSong()
    }, timeToStart)
    if (this.playButton) {
      anim.hide(this.playButton)
      anim.hide(this.finishedEl)
    }
  }

  startCapture ({ framerate = 20, format = 'jpg', quality = 30, renderBackground = true, verbose = false } = {}, callback) {
    this.visualizer.renderBackground = renderBackground
    this.visCanvas.style.display = 'none'
    this.visCanvas.width = this.imagePane.width
    this.visCanvas.height = this.imagePane.height
    this.visualizer.clear({ origin: this.imagePane.origin })

    this.isCapturing = true
    this.capturer = new CCapture({
      format:    format,
      verbose:   verbose,
      quality:   quality,
      framerate: framerate,
      name:      `${format}-${framerate}fps-${this.visCanvas.width}x${this.visCanvas.height}-quality-${quality}`
    })

    //  let captureTime = 0
    let start = null
    let lastTimestamp = null
    const animation = (timestamp) => {
      if (!start) {
        start = timestamp
        lastTimestamp = start
      }
      // const progress = timestamp - start
      const dt = timestamp - lastTimestamp
      lastTimestamp = timestamp
      //  console.log(dt, progress)

      const hasEnded = this.visualizer.renderNext(dt)
      this.capturer.capture(this.visCanvas)
      //  if(progress >= 5000) {
      if (hasEnded) {
        this.stopCapture(callback)
      } else {
        window.requestAnimationFrame(animation)
      }
    }
    window.requestAnimationFrame(animation)
    this.capturer.start()
  }

  stopCapture (callback) {
    // console.log('setting display initial', this.visCtx)
    this.visCanvas.width = this.imgCanvas.width
    this.visCanvas.height = this.imgCanvas.height
    this.visCanvas.style.display = 'initial'
    if (this.isCapturing) {
      this.isCapturing = false
      //  setTimeout(() => {
      this.capturer.stop()
      this.capturer.save(callback)
      // }, 2000)
      // console.log('SAVING!')

      // this.visualizer.renderCallback = () => {
      // }
    }
  }

  showReplay () {
    anim.show(this.finishedEl)
    this.emit('finished playing')
  }

  getPDF (opts, callback) {
    generatePDF(Object.assign({}, { sheet: this.sheetImg, drawing: this.imgCanvas }, opts), callback)
  }

  _createButtons (parent) {
    const playButton = document.createElement('div')
    playButton.setAttribute('class', 'us-button centered')
    playButton.innerText = 'Play'
    this.playButton = playButton
    this.parent.appendChild(playButton)
    playButton.onclick = () => this.play()
    anim.init(playButton, false)

    const finishedEl = document.createElement('div')
    finishedEl.setAttribute('class', 'centered')
    const replayButton = document.createElement('div')
    replayButton.setAttribute('class', 'us-button')
    replayButton.innerText = 'replay'
    finishedEl.appendChild(replayButton)
    replayButton.onclick = () => this.play()

    anim.init(finishedEl, false)

    // const filesButton = document.createElement('div')
    // filesButton.setAttribute('class', 'us-button')
    // filesButton.innerText = 'generate files'
    // finishedEl.appendChild(filesButton)
    // filesButton.onclick = () => this.showFiles()

    this.finishedEl = finishedEl
    parent.appendChild(finishedEl)
  }
}

export default ImagePlayer
