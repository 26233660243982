import Recorder from './lib/audio-recorder-polyfill/WebAudioRecorder.js'

export default class AudioRecorder {
  start (ctx, input) {
    // console.log('starting record', ctx, input)
    this.mediaRecorder = new Recorder(ctx, input)
    const recordedBlobs = []
    this.mediaRecorder.addEventListener('stop', () => {
    //   console.log( 'stop called' )
      // this.download(recordedBlobs)
      const blob = new Blob(recordedBlobs, {
        // type: 'audio/wav'
        type: 'audio/mpeg'
      })
      if (this.callback) {
        this.callback(blob, null)
      } else {
        console.warn('no callback handler for mp3')
      }
    })
    this.mediaRecorder.addEventListener('dataavailable', (event) => {
    //   console.log('data')
      if (event.data && event.data.size > 0) {
        recordedBlobs.push(event.data)
      }
    })
    // this.mediaRecorder.ondataavailable = (event) => {
    //   console.log('data')
    //   if (event.data && event.data.size > 0) {
    //     recordedBlobs.push(event.data);
    //   }
    // }
    this.recordedBlobs = recordedBlobs
    this.mediaRecorder.start()
  }

  stop (callback) {
    this.callback = callback
    // console.log(this.mediaRecorder.requestData())
    if (this.mediaRecorder) this.mediaRecorder.stop()
  }

  download (blobs) {
    // console.log('blobs', blobs)
    //    console.log('blobs', this.mediaRecorder.mimeType)
    //  const blob = new Blob(blobs, {type: 'video/webm'});
    const blob = new Blob(blobs, {
      // type: 'audio/wav'
      type: 'audio/mpeg'
    })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = 'test.mp3'
    document.body.appendChild(a)
    a.click()
    setTimeout(() => {
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    }, 100)
  }
}
