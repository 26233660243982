if (!t)
  var t = {map: function(t2, r2) {
    var n2 = {};
    return r2 ? t2.map(function(t3, o2) {
      return n2.index = o2, r2.call(n2, t3);
    }) : t2.slice();
  }, naturalOrder: function(t2, r2) {
    return t2 < r2 ? -1 : t2 > r2 ? 1 : 0;
  }, sum: function(t2, r2) {
    var n2 = {};
    return t2.reduce(r2 ? function(t3, o2, e) {
      return n2.index = e, t3 + r2.call(n2, o2);
    } : function(t3, r3) {
      return t3 + r3;
    }, 0);
  }, max: function(r2, n2) {
    return Math.max.apply(null, n2 ? t.map(r2, n2) : r2);
  }};
var r = function() {
  var r2 = 5, n2 = 8 - r2, o2 = 1e3;
  function e(t2, n3, o3) {
    return (t2 << 2 * r2) + (n3 << r2) + o3;
  }
  function i2(t2) {
    var r3 = [], n3 = false;
    function o3() {
      r3.sort(t2), n3 = true;
    }
    return {push: function(t3) {
      r3.push(t3), n3 = false;
    }, peek: function(t3) {
      return n3 || o3(), t3 === void 0 && (t3 = r3.length - 1), r3[t3];
    }, pop: function() {
      return n3 || o3(), r3.pop();
    }, size: function() {
      return r3.length;
    }, map: function(t3) {
      return r3.map(t3);
    }, debug: function() {
      return n3 || o3(), r3;
    }};
  }
  function u(t2, r3, n3, o3, e2, i3, u2) {
    this.r1 = t2, this.r2 = r3, this.g1 = n3, this.g2 = o3, this.b1 = e2, this.b2 = i3, this.histo = u2;
  }
  function a() {
    this.vboxes = new i2(function(r3, n3) {
      return t.naturalOrder(r3.vbox.count() * r3.vbox.volume(), n3.vbox.count() * n3.vbox.volume());
    });
  }
  function s(r3, n3) {
    if (n3.count()) {
      var o3 = n3.r2 - n3.r1 + 1, i3 = n3.g2 - n3.g1 + 1, u2 = t.max([o3, i3, n3.b2 - n3.b1 + 1]);
      if (n3.count() == 1)
        return [n3.copy()];
      var a2, s2, h, c, f = 0, v = [], l = [];
      if (u2 == o3)
        for (a2 = n3.r1; a2 <= n3.r2; a2++) {
          for (c = 0, s2 = n3.g1; s2 <= n3.g2; s2++)
            for (h = n3.b1; h <= n3.b2; h++)
              c += r3[e(a2, s2, h)] || 0;
          v[a2] = f += c;
        }
      else if (u2 == i3)
        for (a2 = n3.g1; a2 <= n3.g2; a2++) {
          for (c = 0, s2 = n3.r1; s2 <= n3.r2; s2++)
            for (h = n3.b1; h <= n3.b2; h++)
              c += r3[e(s2, a2, h)] || 0;
          v[a2] = f += c;
        }
      else
        for (a2 = n3.b1; a2 <= n3.b2; a2++) {
          for (c = 0, s2 = n3.r1; s2 <= n3.r2; s2++)
            for (h = n3.g1; h <= n3.g2; h++)
              c += r3[e(s2, h, a2)] || 0;
          v[a2] = f += c;
        }
      return v.forEach(function(t2, r4) {
        l[r4] = f - t2;
      }), function(t2) {
        var r4, o4, e2, i4, u3, s3 = t2 + "1", h2 = t2 + "2", c2 = 0;
        for (a2 = n3[s3]; a2 <= n3[h2]; a2++)
          if (v[a2] > f / 2) {
            for (e2 = n3.copy(), i4 = n3.copy(), u3 = (r4 = a2 - n3[s3]) <= (o4 = n3[h2] - a2) ? Math.min(n3[h2] - 1, ~~(a2 + o4 / 2)) : Math.max(n3[s3], ~~(a2 - 1 - r4 / 2)); !v[u3]; )
              u3++;
            for (c2 = l[u3]; !c2 && v[u3 - 1]; )
              c2 = l[--u3];
            return e2[h2] = u3, i4[s3] = e2[h2] + 1, [e2, i4];
          }
      }(u2 == o3 ? "r" : u2 == i3 ? "g" : "b");
    }
  }
  return u.prototype = {volume: function(t2) {
    return this._volume && !t2 || (this._volume = (this.r2 - this.r1 + 1) * (this.g2 - this.g1 + 1) * (this.b2 - this.b1 + 1)), this._volume;
  }, count: function(t2) {
    var r3 = this.histo;
    if (!this._count_set || t2) {
      var n3, o3, i3, u2 = 0;
      for (n3 = this.r1; n3 <= this.r2; n3++)
        for (o3 = this.g1; o3 <= this.g2; o3++)
          for (i3 = this.b1; i3 <= this.b2; i3++)
            u2 += r3[e(n3, o3, i3)] || 0;
      this._count = u2, this._count_set = true;
    }
    return this._count;
  }, copy: function() {
    return new u(this.r1, this.r2, this.g1, this.g2, this.b1, this.b2, this.histo);
  }, avg: function(t2) {
    var n3 = this.histo;
    if (!this._avg || t2) {
      var o3, i3, u2, a2, s2 = 0, h = 1 << 8 - r2, c = 0, f = 0, v = 0;
      for (i3 = this.r1; i3 <= this.r2; i3++)
        for (u2 = this.g1; u2 <= this.g2; u2++)
          for (a2 = this.b1; a2 <= this.b2; a2++)
            s2 += o3 = n3[e(i3, u2, a2)] || 0, c += o3 * (i3 + 0.5) * h, f += o3 * (u2 + 0.5) * h, v += o3 * (a2 + 0.5) * h;
      this._avg = s2 ? [~~(c / s2), ~~(f / s2), ~~(v / s2)] : [~~(h * (this.r1 + this.r2 + 1) / 2), ~~(h * (this.g1 + this.g2 + 1) / 2), ~~(h * (this.b1 + this.b2 + 1) / 2)];
    }
    return this._avg;
  }, contains: function(t2) {
    var r3 = t2[0] >> n2;
    return gval = t2[1] >> n2, bval = t2[2] >> n2, r3 >= this.r1 && r3 <= this.r2 && gval >= this.g1 && gval <= this.g2 && bval >= this.b1 && bval <= this.b2;
  }}, a.prototype = {push: function(t2) {
    this.vboxes.push({vbox: t2, color: t2.avg()});
  }, palette: function() {
    return this.vboxes.map(function(t2) {
      return t2.color;
    });
  }, size: function() {
    return this.vboxes.size();
  }, map: function(t2) {
    for (var r3 = this.vboxes, n3 = 0; n3 < r3.size(); n3++)
      if (r3.peek(n3).vbox.contains(t2))
        return r3.peek(n3).color;
    return this.nearest(t2);
  }, nearest: function(t2) {
    for (var r3, n3, o3, e2 = this.vboxes, i3 = 0; i3 < e2.size(); i3++)
      ((n3 = Math.sqrt(Math.pow(t2[0] - e2.peek(i3).color[0], 2) + Math.pow(t2[1] - e2.peek(i3).color[1], 2) + Math.pow(t2[2] - e2.peek(i3).color[2], 2))) < r3 || r3 === void 0) && (r3 = n3, o3 = e2.peek(i3).color);
    return o3;
  }, forcebw: function() {
    var r3 = this.vboxes;
    r3.sort(function(r4, n4) {
      return t.naturalOrder(t.sum(r4.color), t.sum(n4.color));
    });
    var n3 = r3[0].color;
    n3[0] < 5 && n3[1] < 5 && n3[2] < 5 && (r3[0].color = [0, 0, 0]);
    var o3 = r3.length - 1, e2 = r3[o3].color;
    e2[0] > 251 && e2[1] > 251 && e2[2] > 251 && (r3[o3].color = [255, 255, 255]);
  }}, {quantize: function(h, c) {
    if (!h.length || c < 2 || c > 256)
      return false;
    var f = function(t2) {
      var o3, i3 = new Array(1 << 3 * r2);
      return t2.forEach(function(t3) {
        o3 = e(t3[0] >> n2, t3[1] >> n2, t3[2] >> n2), i3[o3] = (i3[o3] || 0) + 1;
      }), i3;
    }(h);
    f.forEach(function() {
    });
    var v = function(t2, r3) {
      var o3, e2, i3, a2 = 1e6, s2 = 0, h2 = 1e6, c2 = 0, f2 = 1e6, v2 = 0;
      return t2.forEach(function(t3) {
        (o3 = t3[0] >> n2) < a2 ? a2 = o3 : o3 > s2 && (s2 = o3), (e2 = t3[1] >> n2) < h2 ? h2 = e2 : e2 > c2 && (c2 = e2), (i3 = t3[2] >> n2) < f2 ? f2 = i3 : i3 > v2 && (v2 = i3);
      }), new u(a2, s2, h2, c2, f2, v2, r3);
    }(h, f), l = new i2(function(r3, n3) {
      return t.naturalOrder(r3.count(), n3.count());
    });
    function g(t2, r3) {
      for (var n3, e2 = t2.size(), i3 = 0; i3 < o2; ) {
        if (e2 >= r3)
          return;
        if (i3++ > o2)
          return;
        if ((n3 = t2.pop()).count()) {
          var u2 = s(f, n3), a2 = u2[0], h2 = u2[1];
          if (!a2)
            return;
          t2.push(a2), h2 && (t2.push(h2), e2++);
        } else
          t2.push(n3), i3++;
      }
    }
    l.push(v), g(l, 0.75 * c);
    for (var p = new i2(function(r3, n3) {
      return t.naturalOrder(r3.count() * r3.volume(), n3.count() * n3.volume());
    }); l.size(); )
      p.push(l.pop());
    g(p, c);
    for (var b = new a(); p.size(); )
      b.push(p.pop());
    return b;
  }};
}().quantize, n = function(t2) {
  this.canvas = document.createElement("canvas"), this.context = this.canvas.getContext("2d"), this.width = this.canvas.width = t2.naturalWidth, this.height = this.canvas.height = t2.naturalHeight, this.context.drawImage(t2, 0, 0, this.width, this.height);
};
n.prototype.getImageData = function() {
  return this.context.getImageData(0, 0, this.width, this.height);
};
var o = function() {
};
o.prototype.getColor = function(t2, r2) {
  return r2 === void 0 && (r2 = 10), this.getPalette(t2, 5, r2)[0];
}, o.prototype.getPalette = function(t2, o2, e) {
  var i2 = function(t3) {
    var r2 = t3.colorCount, n2 = t3.quality;
    if (r2 !== void 0 && Number.isInteger(r2)) {
      if (r2 === 1)
        throw new Error("colorCount should be between 2 and 20. To get one color, call getColor() instead of getPalette()");
      r2 = Math.max(r2, 2), r2 = Math.min(r2, 20);
    } else
      r2 = 10;
    return (n2 === void 0 || !Number.isInteger(n2) || n2 < 1) && (n2 = 10), {colorCount: r2, quality: n2};
  }({colorCount: o2, quality: e}), u = new n(t2), a = function(t3, r2, n2) {
    for (var o3 = t3, e2 = [], i3 = 0, u2 = void 0, a2 = void 0, s2 = void 0, h = void 0, c = void 0; i3 < r2; i3 += n2)
      a2 = o3[0 + (u2 = 4 * i3)], s2 = o3[u2 + 1], h = o3[u2 + 2], ((c = o3[u2 + 3]) === void 0 || c >= 125) && (a2 > 250 && s2 > 250 && h > 250 || e2.push([a2, s2, h]));
    return e2;
  }(u.getImageData().data, u.width * u.height, i2.quality), s = r(a, i2.colorCount);
  return s ? s.palette() : null;
}, o.prototype.getColorFromUrl = function(t2, r2, n2) {
  var o2 = this, e = document.createElement("img");
  e.addEventListener("load", function() {
    var i2 = o2.getPalette(e, 5, n2);
    r2(i2[0], t2);
  }), e.src = t2;
}, o.prototype.getImageData = function(t2, r2) {
  var n2 = new XMLHttpRequest();
  n2.open("GET", t2, true), n2.responseType = "arraybuffer", n2.onload = function() {
    if (this.status == 200) {
      var t3 = new Uint8Array(this.response);
      i = t3.length;
      for (var n3 = new Array(i), o2 = 0; o2 < t3.length; o2++)
        n3[o2] = String.fromCharCode(t3[o2]);
      var e = n3.join(""), u = window.btoa(e);
      r2("data:image/png;base64," + u);
    }
  }, n2.send();
}, o.prototype.getColorAsync = function(t2, r2, n2) {
  var o2 = this;
  this.getImageData(t2, function(t3) {
    var e = document.createElement("img");
    e.addEventListener("load", function() {
      var t4 = o2.getPalette(e, 5, n2);
      r2(t4[0], this);
    }), e.src = t3;
  });
};

export default o;
