export function padMelody ({ song }) {
  const melody = song.melody
  if (melody.measureCount < song.measureCount) {
    // pad melody to nearest power of 2
    let p = 1
    while (p < melody.measureCount) p = p * 2
    for (let i = melody.measureCount; i < p; i += 1) {
      melody.addNote({ degree: null, duration: '1n' })
    }
  }
}

export function repeatMelody ({ song }) {
  const melody = song.melody
  const songMeasures = song.measureCount
  const cycleMeasures = melody.measureCount
  if (cycleMeasures < songMeasures) {
    const section = melody.notes.slice()
    while (melody.measureCount + cycleMeasures <= songMeasures) {
      section.forEach((note) => {
        melody.addNote({ degree: note.degree, duration: note.duration, vis: note.vis, startBeam: note.startBeam })
      })
    }
  }
}
