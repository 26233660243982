import abcjs from '../../_snowpack/pkg/abcjs.js'

// const abcArray = ['C', 'D', 'E', 'F', 'G', 'A', 'B', 'c', 'd', 'e', 'f', 'g', 'a', 'b']
// const abcArray = ['A,', 'B,', 'C', 'D', 'E', 'F', 'G', 'A', 'B', 'c', 'd', 'e', 'f', 'g', 'a']

const toAbc = {
  A2: 'A,',
  B2: 'B,',
  C3: 'C',
  D3: 'D',
  E3: 'E',
  F3: 'F',
  G3: 'G',
  A3: 'A',
  B3: 'B',
  C4: 'c',
  D4: 'd',
  E4: 'e',
  F4: 'f',
  G4: 'g',
  A4: 'a',
  B4: 'b',
  C5: 'c\'',
  D5: 'd\'',
  E5: 'e\'',
  F5: 'f\'',
  G5: 'g\'',
  A5: 'a\'',
  B5: 'b\''
}
// aMaj2: ['A2', 'B2', 'C#3', 'D3', 'E3', 'F#3', 'G#3', 'A3', 'B3', 'C#4', 'D4', 'E4', 'F#4', 'G#4', 'A4']

const duration = {
  '8n':  '/2',
  '16n': '/4',
  '4n':  '',
  '2n':  '2',
  '1n':  '1'
}

const generateAbc = (notes, bpm, key, repeat) => (
  `
K:${key}
Q:C=${bpm}
M: 4/4
L: 1/4
${repeat ? '|:' : ''}${notes}${repeat ? ':' : ''}|
`
)

class SheetMusicGenerator {
  constructor ({ song, width, height, bpm, key }) {
    const melody = song.melody
    const repeat = song.repeat

    // console.log(key)

    const getAbc = (note) => {
      note = note.replace('#', '')
      note = note.replace('b', '')
      const abc = toAbc[note]
      return abc
    }

    // const notesCanvas = document.createElement('canvas')
    // notesCanvas.width = width
    // notesCanvas.height = height
    // const ctx = notesCanvas.getContext('2d')

    // <div id='notes' style='z-index:10;position:absolute;bottom:0px;width:100%'></div>
    //   console.log('generating song', notes)
    //    const secsToMeasure = (60 / bpm)//* 4
    //    console.log('bpm', bpm, secsToMeasure)
    let prevMeasure = null
    const lineInterval = melody.measureCount / 3 // go to new line
    let lineBreak = 0 + lineInterval
    const noteStr = melody.notes.map((note) => {
      let bar = ''
      let nl = ''
      if (note.measure !== prevMeasure) {
        if (prevMeasure != null) bar = '|'
        prevMeasure = note.measure
        if (note.measure >= lineBreak) {
          nl = '\n'
          lineBreak += lineInterval
        }
      }
      const chord = note.chordName ? `"${note.chordName}"` : ''
      const beam = note.startBeam ? ' ' : ''
      const pitch = (note.pitch && getAbc(note.pitch)) ?? ' z'
      const str = `${bar}${nl}${chord}${beam}${pitch}${duration[note.duration]}`
      // console.log(str, note)
      return str
    }).join('')

    const notesDiv = document.createElement('div')
    notesDiv.style.display = 'none'
    notesDiv.setAttribute('width', 556 + 'px')
    notesDiv.style.width = 556 + 'px'
    notesDiv.style.height = 300 + 'px'
    notesDiv.setAttribute('height', 300 + 'px')
    notesDiv.setAttribute('id', 'us-sheet-music')
    document.body.appendChild(notesDiv)
    // console.log(noteStr)

    abcjs.renderAbc(
      'us-sheet-music',
      generateAbc(noteStr, bpm, key, repeat),
      { staffwidth: 600, add_classes: true }
    )

    const svg = notesDiv.childNodes[0]
    const xml = new XMLSerializer().serializeToString(svg)
    const data = 'data:image/svg+xml;base64,' + btoa(xml)
    const img = new Image()

    document.body.removeChild(notesDiv)

    img.setAttribute('src', data)
    //  document.body.appendChild(img)
    this.img = img
  }
}

export default SheetMusicGenerator
