import { Scale } from '../Scale.js'

export const scales = [ // ordered from low to high
  new Scale({
    name:    'Fmajor',
    pitches: ['F2', 'G2', 'A2', 'Bb2', 'C3', 'D3', 'E3', 'F3']
  }),
  new Scale({
    name:    'Gmajor',
    pitches: ['G2', 'A2', 'B2', 'C3', 'D3', 'E3', 'F#3', 'G3']
  }),
  new Scale({
    name:    'Amajor',
    pitches: ['A2', 'B2', 'C#3', 'D3', 'E3', 'F#3', 'G#3', 'A3']
  }),
  new Scale({
    name:    'Amajor',
    pitches: ['A3', 'B3', 'C#4', 'D4', 'E4', 'F#4', 'G#4', 'A4']
  }),
  new Scale({
    name:    'Cmajor',
    pitches: ['C3', 'D3', 'E3', 'F3', 'G3', 'A3', 'B3', 'C4']
  }),
  new Scale({
    name:    'Dmajor',
    pitches: ['D3', 'E3', 'F#3', 'G3', 'A3', 'B3', 'C#4', 'D4']
  }),
  new Scale({
    name:    'Fmajor',
    pitches: ['F3', 'G3', 'A3', 'Bb3', 'C4', 'D4', 'E4', 'F4']
  }),
  new Scale({
    name:    'Gmajor',
    pitches: ['G3', 'A3', 'B3', 'C4', 'D4', 'E4', 'F#4', 'G4']
  }),
  new Scale({
    name:    'Cmajor',
    pitches: ['C4', 'D4', 'E4', 'F4', 'G4', 'A4', 'B4', 'C5']
  }),
  new Scale({
    name:    'Dmajor',
    pitches: ['D4', 'E4', 'F#4', 'G4', 'A4', 'B4', 'C#5', 'D5']
  }),
  new Scale({
    name:    'Fmajor',
    pitches: ['F4', 'G4', 'A4', 'Bb4', 'C5', 'D5', 'E5', 'F5']
  })
]
