import { ScalarImage } from './ScalarImage.js'

export class GreyImage extends ScalarImage {
  setValueFromRgb (x, y, rgb) {
    const r = rgb[0]
    const g = rgb[1]
    const b = rgb[2]
    this.setValue(x, y, (0.299 * r) + (0.587 * g) + (0.114 * b))
  }

  // not formal definition of energy, just something useful for us
  getEnergy () {
    let totalBrightness = 0
    let totalDelta = 0
    this.forEachValue((x, y, g) => {
      const delta = Math.max(
        (x > 0) ? Math.abs(this.getValue(x - 1, y) - g) : 0,
        (x < this.width - 1) ? Math.abs(this.getValue(x + 1, y) - g) : 0,
        (y > 0) ? Math.abs(this.getValue(x, y - 1) - g) : 0,
        (y < this.height - 1) ? Math.abs(this.getValue(x, y + 1) - g) : 0
      )

      totalBrightness += g
      totalDelta += delta
    })
    return {
      intensity: totalBrightness / (255 * this.width * this.height),
      edginess:  totalDelta / (11 * this.width * this.height)
    }
  }
}
