// canvas contrast: https://codepen.io/wellingguzman/pen/BxVKRE

import '../../_snowpack/pkg/cropperjs/dist/cropper.css'
import Cropper from '../../_snowpack/pkg/cropperjs.js'

import { getCtx } from './canvas.js'
import { htmlToElement } from './dom.js'

function loadImage (url) {
  return new Promise(resolve => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.src = url
  })
}

class Editor {
  constructor ({ parent, width, height, scale = 0.9 }) {
    this.parent = parent
    this.rootElem = htmlToElement('<div class="us-editor" style="width: 100%; height: 100%"></div>')
    this.canvasElem = htmlToElement('<canvas style="display: block; max-width: 100%"></canvas>')
    this.rootElem.appendChild(this.canvasElem)
    this.width = width
    this.height = height
    this.scale = scale // size in relation to parent
    this.reset()
  }

  destroy () {
    this.cropper?.destroy()
    this.rootElem.remove()
  }

  reset () {
    this.cropper?.reset()
    this.setZoom(0)
    this.setFlip(false)
    this.setBrightness(100)
    this.setContrast(100)
    this.setRotate(0)
  }

  async setData (url) {
    const img = await loadImage(url)
    // to avoid issues with running out of memory on small devices, scale
    // down the image if it has 3x more pixels than the size of the screen
    const scale = Math.min(1, 3 * screen.height * screen.width / (img.width * img.height))
    const width = Math.round(scale * img.width)
    const height = Math.round(scale * img.height)
    this.canvasElem.width = width
    this.canvasElem.height = height
    getCtx(this.canvasElem).drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height)
    this.haveImage = true
  }

  async getContents () {
    if (!this.cropper) throw new Error('No crop instance')
    if (!this.cropEnabled) {
      // We want to get the image that's currently being shown, but if
      // cropping isn't enabled, cropper will return the original image.
      // So we re-enable cropping, set to crop at the dimensions of
      // the currently viewed canvas.
      const canvasData = this.cropper.getCanvasData() // gets the dimensions
      this._reenableCrop({
        x:      canvasData.left,
        y:      canvasData.top,
        height: canvasData.naturalHeight,
        width:  canvasData.naturalWidth
      })
    }
    const canvas = this.cropper.getCroppedCanvas({ })
    this.applyFinalFilters(canvas)
    return canvas
  }

  /* Uses CSS filters while editing for speed, uses canvas pixel manipulation when editing is over in order to edit final canvas */
  setBrightness (amount = 100) {
    this.brightness = amount
    this.applyFilters()
  }

  setContrast (amount = 100) {
    this.contrast = amount
    this.applyFilters()
  }

  _reenableCrop ({ x, y, width, height }) {
    const data = Object.assign({},
      this.cropper.getData(), // get the current rotate & scale values (and disabled crop rectangle)
      { x, y, width, height } // overlay the desired crop rectangle
    )
    this.cropper.crop()
    this.cropper.setData(data)
  }

  enableCrop (enableCrop = true) {
    if (this.cropEnabled === enableCrop) return
    if (this.cropper == null) return

    this.cropEnabled = enableCrop
    if (this.cropEnabled === false) {
      this.saveCrop = this.cropper.getData(true)
      this.cropper.clear()
    } else {
      this._reenableCrop(this.saveCrop)
    }
  }

  setRotate (amount = 90) {
    this.currentRotate = amount
    this.cropper?.rotateTo(amount)
    this._updateZoom()
  }

  setFlip (shouldFlip = true) {
    this.shouldFlip = shouldFlip
    this.cropper?.scaleX(shouldFlip ? -1 : 1)
  }

  setZoom (amount = 0) {
    this.currentZoom = amount
    this._updateZoom()
  }

  _updateZoom () {
    if (this.cropper) {
      this._zoomBase = this._computeZoomBase()
      this.cropper.zoomTo(this._zoomBase + this.currentZoom)
    }
  }

  applyFilters () {
    const filter = `contrast(${this.contrast}%) brightness(${this.brightness}%)`
    this.rootElem.querySelectorAll('img').forEach(img => {
      img.style.filter = filter
      img.style['-webkit-filter'] = filter
    })
  }

  applyFinalFilters (canvas) {
    const ctx = canvas.getContext('2d')
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    this._applyCanvasContrast(imageData.data, this.contrast)
    this._applyCanvasBrightness(imageData.data, this.brightness)
    ctx.putImageData(imageData, 0, 0)
  }

  _applyCanvasContrast (d, _contrast) { // input range [-100..100]
    const contrast = ((_contrast - 100) / 100) + 1 // convert to decimal & shift range: [0..2]
    const intercept = 128 * (1 - contrast)
    for (let i = 0; i < d.length; i += 4) { // r,g,b,a
      d[i] = d[i] * contrast + intercept
      d[i + 1] = d[i + 1] * contrast + intercept
      d[i + 2] = d[i + 2] * contrast + intercept
    }
  }

  _applyCanvasBrightness (data, brightness) {
    for (let i = 0; i < data.length; i += 4) {
      data[i] = data[i] * (brightness / 100)
      data[i + 1] = data[i + 1] * (brightness / 100)
      data[i + 2] = data[i + 2] * (brightness / 100)
    }
  }

  async enableEditing ({ onZoom, onReady } = {}) {
    if (!this.haveImage) return
    this.parent.appendChild(this.rootElem)
    await new Promise(resolve => (this.cropper = new Cropper(this.canvasElem, {
      viewMode:                 2,
      dragMode:                 'move',
      modal:                    true,
      guides:                   false,
      center:                   false,
      highlight:                false,
      background:               false,
      autoCrop:                 true,
      autoCropArea:             1.0,
      toggleDragModeOnDblClick: false,
      zoomOnTouch:              true,
      zoomOnWheel:              true,
      cropBoxMovable:           true,
      minCropBoxWidth:          120,
      minCropBoxHeight:         120,
      ready:                    resolve,
      zoom:                     e => onZoom?.(e.detail.ratio - this._zoomBase)
    })))

    this.baseImageData = { ...this.cropper.getImageData() }
    this.baseContainerData = { ...this.cropper.getContainerData() }
    this._updateZoom()
    this.enableCrop(false)
    this.applyFilters()
    onReady?.()
  }

  _computeZoomBase () {
    const { naturalWidth: ix, naturalHeight: iy } = this.baseImageData
    const { width, height } = this.baseContainerData
    const a = Math.PI * this.currentRotate / 180
    const cos = Math.cos(a)
    const sin = Math.sin(a)
    const x = cos * ix + sin * iy
    const y = cos * iy - sin * ix
    return 1.0 / Math.max(Math.abs(x / width), Math.abs(y / height))
  }
}

export default Editor
