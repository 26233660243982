export const chordTriads = {
  I:   [1, 3, 5],
  ii:  [2, 4, 6],
  iii: [3, 5, 7],
  IV:  [4, 6, 8],
  V:   [5, 7, 9],
  V6:  [5, 7, 10],
  vi:  [6, 8, 10],
  vi6: [6, 8, 11]
}
