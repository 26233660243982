import { isLower, mod, roman2int } from './util.js'

function splitPitch (pitch) {
  const i = pitch.length - 1
  return {
    note:   pitch.substr(0, i),
    octave: parseInt(pitch.substr(i))
  }
}

export class Scale {
  constructor ({ name, pitches }) {
    this.name = name
    this.pitches = pitches
  }

  get length () {
    return this.pitches.length
  }

  getPitch (degree) {
    if (degree == null) return null
    const { octave: octaveShift, rel } = octaveRel(degree)
    const { note, octave } = splitPitch(this.pitches[rel - 1])
    return note + (octave + octaveShift)
  }

  getChordName (chord) {
    const letters = 'ABCDEFG'
    const key     = this.name[0]
    const iScale  = letters.indexOf(key)
    const iChord  = roman2int(chord) - 1
    const pitch   = letters[(iScale + iChord) % 7]
    const minor   = isLower(chord[0]) ? 'm' : ''
    const sixth   = chord[chord.length - 1] === '6' ? '6' : ''
    return pitch + minor + sixth
  }
}

export function octaveRel (degree) {
  const degree0 = degree - 1
  return {
    octave: Math.floor(degree0 / 7),
    rel:    mod(degree0, 7) + 1
  }
}
