import { Analysis } from './Analysis.js'
import { CanvasImage } from './image/index.js'
import { Song } from './Song.js'
import { accompany } from './accompany.js'
import { chordTriads, progressions, scales } from './musicDefinitions/index.js'
import { composeSpiral } from './SpiralComposer.js'
import { getByAlpha, lerp } from './util.js'
import { padMelody, repeatMelody } from './repeat.js'
import SheetMusic from './SheetMusicGenerator.js'

export class Composer {
  constructor ({ imgCanvas, config, pane }) {
    const image = new CanvasImage({ canvas: imgCanvas }).crop(pane)
    this.image = image
    // console.log({ imgCanvas, pane, image })

    const song = new Song()
    this.song = song

    const analysis = Analysis.analyzeImage({ image })
    // analysis.log()

    const { bpm, musicScale, progression, slow } = this.musicalSetting({ analysis, config })
    // console.log({ bpm, musicScale, progression, slow })
    this.bpm = bpm

    if (analysis.edginess === 0 && analysis.variety < 0.1) {
      const triad = chordTriads['I']  /* eslint-disable-line dot-notation */
      song.melody.addNote({ degree: null, duration: '1n' })
      song.addTrack().addChord({ degrees: triad.map(degree => degree - 7).concat(triad), duration: '1n' })
    } else {
      composeSpiral({ image, song, progression, slow, config })
      accompany({ progression, song, analysis })
      padMelody({ song })
    }

    song.assignPitches(musicScale)

    this.sheet = new SheetMusic({
      width:  287,
      height: 549,
      bpm,
      song
    })

    if (song.repeat) repeatMelody({ song })
  }

  musicalSetting ({ analysis, config }) {
    const slow        = (analysis.energy < 0.5) + (analysis.intensity < 0.5) + (analysis.coolness < 0.5) >= 2
    const bpm         = Math.round(config.bpm.value * lerp(0.8, 1.2, analysis.intensity))
    const musicScale  = this.musicScale = getByAlpha(scales, analysis.lightness)
    const progression = getByAlpha(progressions, analysis.coolness)
    return { bpm, musicScale, progression, slow }
  }
}
