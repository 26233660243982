export function createCanvas (width, height, scale) {
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  canvas.style.width = '100%'
  canvas.style.position = 'absolute'
  canvas.style.top = '50%'
  canvas.style['-ms-transform'] = `translateY(-50%) translateX(-50%) scale(${scale})`
  canvas.style.transform = `translateY(-50%) translateX(-50%) scale(${scale})`
  return canvas
}

export function getCtx (canvas) {
  return canvas.getContext('2d')
}

export function createCanvasCtx (...args) {
  const canvas = createCanvas(...args)
  return {
    canvas,
    ctx: getCtx(canvas)
  }
}

//
//  Creates a new canvas with the given outer dimensions, with the given
//  image letterboxed in it Returns the canvas and the measurements of the
//  rectangle of the letterboxed image within the canvas.
//
// `image` is anything that can be passed to drawImage(), i.e. "any canvas
// image source (CanvasImageSource), specifically, a CSSImageValue, an
// HTMLImageElement, an SVGImageElement, an HTMLVideoElement, an
// HTMLCanvasElement, an ImageBitmap, or an OffscreenCanvas."
//
export function letterbox (image, width, height) {
  const scale = Math.min(width / image.width, height / image.height)
  const pane = {
    width:  Math.round(scale * image.width),
    height: Math.round(scale * image.height)
  }
  pane.origin = {
    x: Math.round((width - pane.width) / 2),
    y: Math.round((height - pane.height) / 2)
  }

  const canvas = createCanvas(width, height)
  getCtx(canvas).drawImage(image, 0, 0, image.width, image.height, pane.origin.x, pane.origin.y, pane.width, pane.height)
  return { canvas, pane }
}
