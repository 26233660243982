// ordered from lowest- to highest- energy
export const progressions = [
  ['I', 'V', 'vi', 'iii', 'IV', 'I', 'IV', 'V', 'I'],
  ['I', 'IV', 'ii', 'V', 'I', 'IV', 'ii', 'V', 'I'],
  ['I', 'IV', 'vi', 'V', 'I', 'IV', 'vi', 'V', 'I'],
  ['I', 'vi', 'IV', 'V', 'I', 'vi', 'IV', 'V', 'I'],
  ['I', 'V', 'vi', 'IV', 'I', 'V', 'vi', 'IV', 'I'],
  ['vi', 'V', 'IV', 'V', 'vi', 'V', 'IV', 'V', 'I'],
  ['I', 'V', 'IV', 'V', 'I', 'V', 'IV', 'V', 'I'],
  ['vi6', 'ii', 'V6', 'I', 'vi6', 'ii', 'V6', 'I', 'I'],
  ['vi', 'IV', 'I', 'V', 'vi', 'IV', 'I', 'V', 'I'],
  ['vi', 'V', 'IV', 'iii', 'vi', 'V', 'IV', 'iii', 'I'],
  ['I', 'vi', 'ii', 'V', 'I', 'vi', 'ii', 'V', 'I']
]
