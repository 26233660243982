import { ColorImage } from './ColorImage.js'

export class CanvasImage extends ColorImage {
  constructor ({ canvas, width, height, origin }) {
    if (canvas) {
      width = canvas.width
      height = canvas.height
    } else {
      canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
    }
    const ctx = canvas.getContext('2d')
    super({ width: canvas.width, height: canvas.height, channels: 4, array: ctx.getImageData(0, 0, width, height).data, origin })
    this.canvas = canvas
    this.ctx = ctx
  }

  crop ({ origin, width, height }) {
    const cropped = new CanvasImage({
      width,
      height,
      origin: { x: this.origin.x + origin.x, y: this.origin.y + origin.y }
    })
    cropped.ctx.drawImage(this.canvas, origin.x, origin.y, width, height, 0, 0, width, height)
    cropped._updateArray()
    return cropped
  }

  getImageData () {
    return this.ctx.getImageData(0, 0, this.width, this.height)
  }

  scaled ({ scale, width, height, smooth = true }) {
    const scaled = new CanvasImage({ width: width ?? this.width * scale, height: height ?? this.height * scale })
    scaled.fillFromCanvas(this.canvas, { smooth })
    return scaled
  }

  fillFromUint32 (array) {
    const imageData = this.ctx.createImageData(this.width, this.height)
    imageData.data.set(new Uint8ClampedArray(array))
    this.ctx.putImageData(imageData, 0, 0)
    this._updateArray()
  }

  fillFromCanvas (canvas, { smooth = true }) {
    this.ctx.imageSmoothingEnabled = smooth
    this.ctx.drawImage(canvas, 0, 0, this.width, this.height)
    this._updateArray()
  }

  _updateArray () {
    this.array = this.ctx.getImageData(0, 0, this.width, this.height).data
  }
}
