function z (n) { return isFinite(n) ? n : 0 }

class Edge {
  constructor (v1, v2) {
    if (v2.y < v1.y) [v1, v2] = [v2, v1]
    this.vertex1 = { x: v1.x, y: v1.y }
    this.payload1 = v1.payload ?? 0
    this.vertex2 = { x: v2.x, y: v2.y }
    this.payload2 = v2.payload ?? 0
  }

  get xdiff () { return this.vertex2.x - this.vertex1.x }
  get ydiff () { return this.vertex2.y - this.vertex1.y }

  isLeftOf (that) {
    return this.vertex1.x < that.vertex1.x || this.vertex2.x < that.vertex2.x
  }
}

class Span {
  constructor (payload1, payload2, x1, x2, e1left) {
    if (!e1left) [payload1, payload2, x1, x2] = [payload2, payload1, x2, x1]
    this.payload1 = payload1
    this.payload2 = payload2
    this.x1 = x1
    this.x2 = x2
  }

  get xdiff () { return this.x2 - this.x1 }
}

export class TriangleRasterizer {
  constructor ({ payloadDiff, payloadInterp, getIndex }) {
    this.payloadDiff = payloadDiff ?? ((p1, p2) => p2 - p1)
    this.payloadInterp = payloadInterp ?? ((p, diff, factor) => p + diff * factor)
    this.getIndex = getIndex
  }

  rasterize (v0, v1, v2, func) {
    const edges = [new Edge(v0, v1), new Edge(v1, v2), new Edge(v2, v0)]

    let maxLength = 0
    let longEdge = 0

    for (let i = 0; i < 3; i++) {
      const len = edges[i].ydiff
      if (len > maxLength) {
        maxLength = len
        longEdge = i
      }
    }

    const shortEdge1 = (longEdge + 1) % 3
    const shortEdge2 = (longEdge + 2) % 3
    this.drawSpansBetweenEdges(edges[longEdge], edges[shortEdge1], func)
    this.drawSpansBetweenEdges(edges[longEdge], edges[shortEdge2], func)
  }

  drawSpansBetweenEdges (e1, e2, func) {
    const e1ydiff = e1.ydiff
    const e2ydiff = e2.ydiff
    const e1payloaddiff = this.payloadDiff(e1.payload1, e1.payload2)
    const e2payloaddiff = this.payloadDiff(e2.payload1, e2.payload2)
    const e1left = e1.isLeftOf(e2)

    const factorStep1 = z(1 / e1ydiff)
    const factorStep2 = z(1 / e2ydiff)

    let factor1 = z((e2.vertex1.y - e1.vertex1.y) / e1ydiff)
    let factor2 = 0

    for (let y = e2.vertex1.y; y <= e2.vertex2.y; y++) {
      const span = new Span(
        this.payloadInterp(e1.payload1, e1payloaddiff, factor1),
        this.payloadInterp(e2.payload1, e2payloaddiff, factor2),
        e1.vertex1.x + Math.floor(e1.xdiff * factor1),
        e2.vertex1.x + Math.floor(e2.xdiff * factor2),
        e1left)
      this.drawSpan(span, y, func)
      factor1 += factorStep1
      factor2 += factorStep2
    }
  }

  drawSpan (span, y, func) {
    const xdiff = span.xdiff
    const payloaddiff = this.payloadDiff(span.payload1, span.payload2)
    const factorStep = z(1 / xdiff)
    let factor = 0

    for (let x = span.x1; x <= span.x2; x++) {
      const payload = this.payloadInterp(span.payload1, payloaddiff, factor)
      func(x, y, this.getIndex(x, y), payload)
      factor += factorStep
    }
  }
}
