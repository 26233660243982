// ordered from lowest- to highest-energy
export const articulations = [
  // bar 17 of rhythm samples sheet
  [
    { offset: 0, triadIndex: 0, octave: 0, duration: '1n' },
    { offset: 0, triadIndex: 0, octave: -1, duration: '1n' },
    // { offset: 0, triadIndex: 0, octave: -2, duration: '1n' },

    { offset: 0, triadIndex: 2, octave: 0, duration: '8n' },
    { offset: 2, triadIndex: 0, octave: 1, duration: '8n' },
    { offset: 4, triadIndex: 0, octave: 1, passing: 1, duration: '8n' },
    { offset: 6, triadIndex: 1, octave: 1, duration: '4n' },
    { offset: 10, triadIndex: 0, octave: 1, passing: 1, duration: '8n' },
    { offset: 12, triadIndex: 0, octave: 1, duration: '8n' },
    { offset: 14, triadIndex: 2, octave: 0, duration: '8n' }
  ],

  // bar 13
  [
    { offset: 0, triadIndex: 1, octave: 1, duration: '4n' },
    { offset: 0, triadIndex: 0, octave: 1, duration: '4n' },
    { offset: 0, triadIndex: 2, octave: 0, duration: '4n' },
    { offset: 4, triadIndex: 1, octave: 1, duration: '4n' },
    { offset: 4, triadIndex: 0, octave: 1, duration: '4n' },
    { offset: 4, triadIndex: 2, octave: 0, duration: '4n' },
    { offset: 8, triadIndex: 1, octave: 1, duration: '4n' },
    { offset: 8, triadIndex: 0, octave: 1, duration: '4n' },
    { offset: 8, triadIndex: 2, octave: 0, duration: '4n' },
    { offset: 12, triadIndex: 1, octave: 1, duration: '16n' },
    { offset: 12, triadIndex: 2, octave: 0, duration: '16n' },
    { offset: 13, triadIndex: 2, octave: 1, passing: -1, duration: '16n' },
    { offset: 14, triadIndex: 0, octave: 1, duration: '8n' },

    // { offset: 0, triadIndex: 0, octave: -2, duration: '0:2:3' },
    { offset: 0, triadIndex: 0, octave: -1, duration: '0:2:3' },
    { offset: 0, triadIndex: 0, octave: 0, duration: '0:2:3' },
    { offset: 11, triadIndex: 0, octave: 0, duration: '0:1:1' }
  ],

  // bar 21
  [
    { offset: 0, triadIndex: 1, octave: 1, duration: '1n' },
    { offset: 0, triadIndex: 0, octave: 1, duration: '1n' },
    { offset: 0, triadIndex: 2, octave: 0, duration: '1n' },

    // { offset: 0, triadIndex: 0, octave: -2, duration: '8n' },
    { offset: 0, triadIndex: 0, octave: -1, duration: '8n' },
    { offset: 2, triadIndex: 2, octave: -1, duration: '8n' },
    { offset: 4, triadIndex: 1, octave: 0, duration: '8n' },
    { offset: 6, triadIndex: 0, octave: 0, duration: '8n' },
    { offset: 8, triadIndex: 1, octave: 0, duration: '8n' },
    { offset: 10, triadIndex: 0, octave: 0, duration: '8n' },
    { offset: 12, triadIndex: 1, octave: 0, duration: '8n' },
    { offset: 14, triadIndex: 0, octave: 0, duration: '8n' }
  ],

  // bar 9
  [
    { offset: 0, triadIndex: 1, octave: 1, duration: '8n.' },
    { offset: 0, triadIndex: 0, octave: 1, duration: '8n.' },
    { offset: 0, triadIndex: 2, octave: 0, duration: '8n.' },
    { offset: 3, triadIndex: 2, octave: 0, duration: '16n' },
    { offset: 4, triadIndex: 0, octave: 1, duration: '8n' },
    { offset: 6, triadIndex: 1, octave: 1, duration: '8n' },
    { offset: 8, triadIndex: 0, octave: 1, duration: '8n' },
    { offset: 10, triadIndex: 2, octave: 0, duration: '8n' },
    { offset: 12, triadIndex: 0, octave: 1, duration: '8n' },
    { offset: 12, triadIndex: 1, octave: 1, duration: '8n' },
    { offset: 14, triadIndex: 2, octave: 0, duration: '8n' },

    // { offset: 0, triadIndex: 0, octave: -2, duration: '4n' },
    { offset: 0, triadIndex: 0, octave: -1, duration: '8n' },
    { offset: 0, triadIndex: 0, octave: 0, duration: '8n' },
    { offset: 2, triadIndex: 0, octave: 0, duration: '0:2:2' },
    { offset: 12, triadIndex: 0, octave: 0, duration: '4n' }
  ],

  // bar 5
  [
    // { offset: 0, triadIndex: 0, octave: -2, duration: '2n.' },
    { offset: 0, triadIndex: 0, octave: 0, duration: '2n.' },
    { offset: 0, triadIndex: 0, octave: -1, duration: '2n.' },
    { offset: 12, triadIndex: 0, octave: 0, duration: '4n' },

    { offset: 0, triadIndex: 0, octave: 1, duration: '8n' },
    { offset: 0, triadIndex: 1, octave: 1, duration: '8n' },
    { offset: 2, triadIndex: 2, octave: 0, duration: '8n' },

    { offset: 4, triadIndex: 0, octave: 1, duration: '8n' },
    { offset: 4, triadIndex: 1, octave: 1, duration: '8n' },
    { offset: 6, triadIndex: 2, octave: 0, duration: '8n' },

    { offset: 8, triadIndex: 0, octave: 1, duration: '8n' },
    { offset: 8, triadIndex: 1, octave: 1, duration: '8n' },
    { offset: 10, triadIndex: 2, octave: 0, duration: '16n' },
    { offset: 11, triadIndex: 0, octave: 1, duration: '16n' },
    { offset: 12, triadIndex: 0, octave: 1, passing: 1, duration: '16n' },
    { offset: 13, triadIndex: 1, octave: 1, duration: '16n' },
    { offset: 14, triadIndex: 0, octave: 1, passing: 1, duration: '16n' },
    { offset: 15, triadIndex: 0, octave: 1, duration: '16n' }
  ],

  /*
  // bar 25
  [
    { offset: 0, triadIndex: 1, octave: 1, duration: '8n' },
    { offset: 0, triadIndex: 2, octave: 0, duration: '8n' },
    { offset: 2, triadIndex: 0, octave: 1, passing: 1, duration: '8n' },
    { offset: 4, triadIndex: 0, octave: 1, duration: '8n' },
    { offset: 6, triadIndex: 1, octave: 1, duration: '8n' },
    { offset: 6, triadIndex: 2, octave: 0, duration: '8n' },
    { offset: 8, triadIndex: 0, octave: 1, passing: 1, duration: '8n' },
    { offset: 10, triadIndex: 0, octave: 1, duration: '8n' },
    { offset: 12, triadIndex: 1, octave: 1, duration: '8n' },
    { offset: 12, triadIndex: 2, octave: 0, duration: '8n' },
    { offset: 14, triadIndex: 0, octave: 1, passing: 1, duration: '8n' },

    // { offset: 0, triadIndex: 0, octave: -2, duration: '4n.' },
    { offset: 0, triadIndex: 0, octave: -1, duration: '4n.' },
    { offset: 0, triadIndex: 0, octave: 0, duration: '4n.' },
    { offset: 6, triadIndex: 0, octave: -1, duration: '4n.' },
    { offset: 6, triadIndex: 0, octave: 0, duration: '4n.' },
    { offset: 12, triadIndex: 0, octave: -1, duration: '4n' },
    { offset: 12, triadIndex: 0, octave: 0, duration: '4n' }
  ],
  */

  // bar 1
  [
    { offset: 0, triadIndex: 1, octave: 1, duration: '8n.' },
    { offset: 0, triadIndex: 0, octave: 1, duration: '8n.' },
    { offset: 0, triadIndex: 2, octave: 0, duration: '8n.' },
    { offset: 3, triadIndex: 2, octave: 0, duration: '16n' },
    { offset: 4, triadIndex: 1, octave: 1, duration: '8n' },
    { offset: 4, triadIndex: 0, octave: 1, duration: '8n' },
    { offset: 4, triadIndex: 2, octave: 0, duration: '8n' },
    { offset: 6, triadIndex: 2, octave: 0, duration: '16n' },
    { offset: 7, triadIndex: 1, octave: 1, duration: '8n' },
    { offset: 7, triadIndex: 0, octave: 1, duration: '8n' },
    { offset: 7, triadIndex: 2, octave: 0, duration: '8n' },
    { offset: 9, triadIndex: 1, octave: 1, duration: '8n.' },
    { offset: 9, triadIndex: 0, octave: 1, duration: '8n.' },
    { offset: 9, triadIndex: 2, octave: 0, duration: '8n.' },
    { offset: 12, triadIndex: 1, octave: 1, duration: '16n' },
    { offset: 12, triadIndex: 0, octave: 1, duration: '16n' },
    { offset: 12, triadIndex: 2, octave: 0, duration: '16n' },
    { offset: 13, triadIndex: 2, octave: 0, duration: '16n' },
    { offset: 14, triadIndex: 0, octave: 1, duration: '16n' },
    { offset: 15, triadIndex: 1, octave: 1, duration: '16n' },

    // { offset: 0, triadIndex: 0, octave: -2, duration: '8n' },
    { offset: 0, triadIndex: 0, octave: 0, duration: '8n' },
    { offset: 0, triadIndex: 0, octave: -1, duration: '8n' },
    { offset: 2, triadIndex: 0, octave: 0, duration: '8n' },
    { offset: 5, triadIndex: 0, octave: 0, duration: '0:1:2' },
    { offset: 11, triadIndex: 0, octave: 0, duration: '0:1:1' }
  ],

  // bar 29
  [
    { offset: 0, triadIndex: 1, octave: 1, duration: '4n.' },
    { offset: 0, triadIndex: 0, octave: 1, duration: '4n.' },
    { offset: 0, triadIndex: 2, octave: 0, duration: '4n.' },
    { offset: 6, triadIndex: 1, octave: 1, duration: '8n' },
    { offset: 6, triadIndex: 0, octave: 1, duration: '8n' },
    { offset: 6, triadIndex: 2, octave: 0, duration: '8n' },
    { offset: 10, triadIndex: 1, octave: 1, duration: '8n' },
    { offset: 10, triadIndex: 0, octave: 1, duration: '8n' },
    { offset: 10, triadIndex: 2, octave: 0, duration: '8n' },
    { offset: 14, triadIndex: 1, octave: 1, duration: '8n' },
    { offset: 14, triadIndex: 0, octave: 1, duration: '8n' },
    { offset: 14, triadIndex: 2, octave: 0, duration: '8n' },

    // { offset: 0, triadIndex: 0, octave: -2, duration: '4n' },
    { offset: 0, triadIndex: 0, octave: -1, duration: '4n' },
    { offset: 4, triadIndex: 0, octave: 0, duration: '8n' },
    { offset: 6, triadIndex: 0, octave: -1, duration: '8n' },
    { offset: 10, triadIndex: 0, octave: 0, duration: '8n' },
    { offset: 14, triadIndex: 0, octave: -1, duration: '8n' }
  ]
]
