import { htmlToElement } from './dom.js'
import anim from './animation.js'

export class Uploader {
  constructor ({ parent, onLoaded }) {
    this.onLoaded = onLoaded
    this.root = htmlToElement(`
    <div>
      <input type="file" name="file" id="upload-drawing" accept="image/jpeg, image/png" class="us-inputfile us-button" />
      <label class="us-button centered" for="upload-drawing">Choose a file...</label>
    </div>
    `)
    parent.appendChild(this.root)
    const input = this.root.querySelector('input')
    anim.init(input, true)
    input.addEventListener('change', (e) => {
      if (e.target.files && e.target.files.length > 0) {
        this._handleFiles(e.target.files)
      }
    })
    this._activateDropZone(this.root)
  }

  destroy () {
    this.root.remove()
  }

  async _handleFiles (files) {
    const file = [...files][0]
    const reader = new FileReader()
    reader.onloadend = (e) => {
      const url = e.target.result
      this.onLoaded({ file, url })
    }
    reader.readAsDataURL(file)
  }

  _activateDropZone (dropArea) {
    const preventDefaults = (e) => {
      e.preventDefault()
      e.stopPropagation()
    }
    dropArea.style.width = '100%'
    dropArea.style.height = '100%'
    const highlight = (e) => {
      // console.log('adding class ')
      e.target.classList.add('highlight')
    }
    const unhighlight = (e) => {
      e.target.classList.remove('highlight')
    }
    ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      dropArea.addEventListener(eventName, preventDefaults, false)
      document.body.addEventListener(eventName, preventDefaults, false)
    })
    ;['dragenter', 'dragover'].forEach(eventName => {
      dropArea.addEventListener(eventName, highlight, false)
    })
    ;['dragleave', 'drop'].forEach(eventName => {
      dropArea.addEventListener(eventName, unhighlight, false)
    })

    dropArea.addEventListener('drop', (e) => {
      // console.log('DROP HAPPENED')
      const dt = e.dataTransfer
      const files = dt.files
      this._handleFiles(files)
    }, false)
  }
}
