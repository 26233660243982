export function clamp (n, a, z) {
  return Math.max(a, Math.min(z, n))
}

export function range (n) {
  const range = Array(n)
  while (--n >= 0) {
    range[n] = n
  }
  return range
}

export function lerp (a, b, alpha) {
  return a * (1 - alpha) + b * alpha
}

export function getByAlpha (arr, alpha) {
  const n = arr.length - 1
  return arr[clamp(Math.round(n * alpha), 0, n)]
}

export function stdev (arr) {
  const mean = arr.reduce((sum, x) => sum + x, 0) / arr.length
  const variance = arr.reduce((sum, x) => sum + (x - mean) * (x - mean)) / arr.length
  return Math.sqrt(variance)
}

export function uniq (arr) {
  const s = new Set()
  return arr.filter(x => s.has(x) ? false : (s.add(x) && true))
}

// proper modulus function since % operator returns negaive for negative
// input.   see https://stackoverflow.com/a/4467559/1056941
export function mod (a, n) {
  return ((a % n) + n) % n
}

export function isLower (s) {
  return s.toLowerCase() === s
}

export function roman2int (roman) {
  roman = roman.toLowerCase().replace(/[^iv].*/, '')
  switch (roman) {
  case 'i':   return 1
  case 'ii':  return 2
  case 'iii': return 3
  case 'iv':  return 4
  case 'v':   return 5
  case 'vi':  return 6
  case 'vii': return 7
  }
  throw new Error(`don't know what to do with roman numeral string '${roman}'`)
}

export function indexOfMaxBy (arr, key) {
  if (arr.length === 0) return null
  let max = key(arr[0])
  let iMax = 0
  for (let i = 1; i < arr.length; i++) {
    const val = key(arr[i])
    if (val > max) {
      max = val
      iMax = i
    }
  }
  return iMax
}

export function minBy (arr, key) {
  const i = indexOfMaxBy(arr, val => -key(val))
  return arr[i]
}

export function maxBy (arr, key) {
  const i = indexOfMaxBy(arr, key)
  return arr[i]
}

export function partition (arr, predicate) {
  return arr.reduce((acc, e) => {
    (predicate(e) ? acc.true : acc.false).push(e)
    return acc
  }, { true: [], false: [] })
}

export function mean (...values) {
  return values.reduce((a, b) => a + b) / values.length
}

export function meanPt (...pts) {
  const sum = pts.reduce((a, b) => ({ x: a.x + b.x, y: a.y + b.y }), { x: 0, y: 0 })
  return {
    x: Math.round(sum.x / pts.length),
    y: Math.round(sum.y / pts.length)
  }
}

export function sq (a) {
  return a * a
}
