// C major key
import { getSampler } from './Instruments.js'
import AudioRecorder from './AudioRecorder.js'

class Sound {
  constructor ({ config }) {
    this.currentEvents = []
    this.config = config
    this.recorder = new AudioRecorder()
    this.ctx = Tone.context
    this.mp3 = null
    this._time = 0
    this.config = config
    this.mixed = new Tone.Mono()
    this.merge = new Tone.Merge().connect(this.mixed)
    this.melodyVolume = new Tone.Volume(0).connect(this.merge, 0, 0)
    const reverb = {
      decay:    8,
      wet:      0.6,
      preDelay: 0.1
    }
    this.accompanimentReverb = new Tone.Reverb(reverb).connect(this.merge, 0, 1)
    this.reverbPromise = this.accompanimentReverb.generate()
    this.accompanimentVolume = new Tone.Volume(-3).connect(this.accompanimentReverb)

    this.mixed.toMaster()
  }

  _configSampler (sampler, nextNode) {
    sampler.attack = this.config.attack.value
    sampler.release = this.config.release.value
    sampler.hold = this.config.noteHold.value
    sampler.connect(nextNode)
  }

  setSong ({ song, bpm }) {
    this.song = song
    this.bpm = bpm
  }

  setMelodyInstrument (instrument) {
    this.melodySamplerPromise = getSampler(instrument)
  }

  setAccompanimentInstrument (instrument) {
    this.accompanimentSamplerPromise = getSampler(instrument)
  }

  async prepare () {
    await this.reverbPromise

    this.melodySampler = await this.melodySamplerPromise
    this.melodySampler.attack = this.config.attack.value
    this.melodySampler.release = this.config.release.value
    this.melodySampler.hold = this.config.noteHold.value
    this.melodySampler.connect(this.melodyVolume)

    this.accompanimentSampler = await this.accompanimentSamplerPromise
    this.accompanimentSampler.attack = this.config.attack.value
    this.accompanimentSampler.release = this.config.release.value
    this.accompanimentSampler.hold = this.config.noteHold.value
    this.accompanimentSampler.connect(this.accompanimentVolume)
  }

  playSong (callback) {
  //  console.log('playing song')
    Tone.Transport.bpm.value = this.bpm
    this.currentEvents.forEach((event) => Tone.Transport.clear(event))
    Tone.Transport.stop()

    this.song.tracks.forEach((track) => {
      const sampler = (track === this.song.melody) ? this.melodySampler : this.accompanimentSampler
      track.notes.forEach((note) => {
        if (note.degree !== null) {
          this.currentEvents.push(
            Tone.Transport.schedule((time) => {
              sampler.triggerAttackRelease(note.pitch, note.durationSeconds /* * this.config.noteHold.value */, time)
            }, note.time)
          )
        }
      })
    })
    Tone.Transport.schedule((time) => {
      if (this.isRecording) {
        //    console.log('stopping record')
        setTimeout(() => this.recorder.stop((mp3, err) => {
          this.mp3 = mp3
          //      console.log('mp3 is', mp3)
        }), 2500)
        this.isRecording = false
      }
      callback()
    }, this.song.lengthSeconds)

    Tone.Transport.start()

    // on first time playing, record mP3
    if (this.mp3 === null) {
      this.isRecording = true
      this.recorder.start(Tone.context, this.mixed)
    } else {
      this.mixed.toMaster()
    }
  }
}

export default Sound
