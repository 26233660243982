export default {

  refresh: {
    type:   'button',
    label:  'refresh grid',
    hidden: true
  },
  bpm: {
    value:  60,
    label:  'bpm',
    min:    20,
    max:    120,
    type:   'range',
    step:   1,
    hidden: true
  },
  circleFade: {
    value: 800,
    label: 'circle fade (ms)',
    min:   0,
    max:   4000,
    type:  'range',
    step:  1
  },
  circleGrowth: {
    value: 30,
    label: 'circle growth',
    min:   0,
    max:   70,
    type:  'range',
    step:  1
  },
  animationType: {
    value:   'fade',
    label:   'fade type',
    options: ['gradient', 'drawing', 'fade', 'drawingGradient', 'drawingFade', 'drawingGradientReverse'],
    type:    'select'
  },
  color: {
    value:   'yellow',
    label:   'color',
    options: ['purple', 'red', 'yellow', 'pink', 'green', 'blue', 'rainbow'],
    type:    'select'
  },
  blendMode: {
    value:   'lighter',
    label:   'blend mode',
    options: ['lighten', 'sourceOver', 'darken', 'difference'],
    type:    'select',
    hidden:  true
  },
  growthEasing: {
    value:   'easeOutQuad',
    label:   'growth easing',
    options: ['linear', 'easeInQuad', 'easeOutQuad', 'easeInOutQuad'],
    type:    'select',
    hidden:  true
  },
  fadeEasing: {
    value:   'easeOutQuad',
    label:   'fade easing',
    options: ['linear', 'easeInQuad', 'easeOutQuad', 'easeInOutQuad'],
    type:    'select',
    hidden:  true
  },
  noteHold: {
    value: 3,
    label: 'note hold',
    min:   0.1,
    max:   4,
    type:  'range',
    step:  0.1
  },
  attack: {
    value:    1.2,
    label:    'note attack',
    min:      0.0,
    max:      1.5,
    type:     'range',
    step:     0.05,
    callback: val => (window.piano.attack = val)
  },
  release: {
    value:    1.5,
    label:    'note release',
    min:      0.0,
    max:      1.5,
    type:     'range',
    step:     0.01,
    callback: val => (window.piano.release = val)
  }
}
