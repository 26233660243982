// import * as anim from './animation'
import { EventEmitter } from '../../_snowpack/pkg/events.js'
import { Uploader } from './Uploader.js'
import Editor from './Editor.js'
import ImagePlayer from './ImagePlayer.js'
import config from './config.js'

class Creator extends EventEmitter {
  constructor ({ parent, width = 800, height = 600, scale = 0.9 } = {}) {
    super()
    this.width = width
    this.height = height
    this.parent = parent
    this.config = config
    this.scale = scale // size of canvas in relation to parent width

    this.container = this.parent

    this.player = new ImagePlayer({
      width:  width,
      height: height,
      parent: this.container,
      config: this.config,
      scale:  this.scale
    })

    this.player.on('started playing', () => this.emit('started playing'))
    this.player.on('finished playing', () => this.emit('finished playing'))
    // window.creator = this

    this.editor = new Editor({ parent: this.container, width: width, height: height, scale: this.scale })
    this.uploader = new Uploader({
      parent,
      onLoaded: async ({ file, url }) => {
        await this.editor.setData(url)
        this.emit('image loaded', file)
      }
    })
  }

  async enableEditing (options = {}) {
    this.uploader.destroy()
    await this.editor.enableEditing(options)
  }

  async generateMelody () {
    this.canvas = await this.editor.getContents()
    this.editor.destroy()
    this.player.setImage(this.canvas)
    this.emit('melody ready')
  }

  getPDF (opts, callback) {
    this.player.getPDF(opts, callback)
  }

  getMP3 (callback) {
    this.player.getMP3(callback)
  }

  getImage (callback, format = 'jpeg') {
    this.player.getImage(format).then(blob => callback(blob))
  }

  getImageDimensions () {
    return this.player.getImagePane()
  }

  getAnimationSequence (opts, callback) {
    this.player.startCapture(opts, callback)
  }

  setRotate (amount) {
  //  console.log('rotating')
    this.editor.setRotate(amount)
  }

  setFlip (flipped) {
    this.editor.setFlip(flipped)
  }

  enableCrop (value) {
    this.editor.enableCrop(value)
  }

  setBrightness (amount) {
    // console.log('setting brightness', amount)
    this.editor.setBrightness(amount)
  }

  setContrast (amount) {
  //  console.log('rotating')
    this.editor.setContrast(amount)
  }

  setZoom (amount) {
    this.editor.setZoom(parseFloat(amount))
  }

  resetEdits () {
    this.editor.reset()
  }

  setMelodyInstrument (instrument) {
    this.player.setMelodyInstrument(instrument)
  }

  setAccompanimentInstrument (instrument) {
    this.player.setAccompanimentInstrument(instrument)
  }
}

export default Creator
