const state = {
  samplesRootUrl: null,
  instruments:    null
}

function sampleUrl (rel) {
  return `${state.samplesRootUrl}/${rel}`.replace(/[/]+/g, '/')
}

export async function setInstruments ({ samplesRootUrl, instruments }) {
  state.samplesRootUrl = samplesRootUrl
  state.instruments = instruments
}

export async function getSampler (key) {
  const instrument = state.instruments[key]
  const samples = {}
  instrument.samples.forEach(note => {
    samples[note.replace('s', '#')] = sampleUrl(`${key}/${note.replace('#', 's')}-${key}.[mp3|ogg]`)
  })
  return new Promise((resolve, reject) => {
    const sampler = new Tone.Sampler(samples, () => resolve(sampler))
  })
}
