import Color from '../../../_snowpack/pkg/color.js'
import ColorThief from '../../../_snowpack/pkg/colorthief.js'

import { GreyImage } from './GreyImage.js'
import { ImageBase } from './ImageBase.js'
import { LabImage } from './LabImage.js'
import { mean, sq } from '../util.js'

export class ColorImage extends ImageBase {
  constructor ({ width, height, channels = 3, array, origin }) {
    super({ width, height, channels, array, origin })
  }

  forEachRgb (func) {
    this.forEachIndex((x, y, i) => func(x, y, this.rgbAtIndex(i)))
  }

  forEachColor (func) {
    this.forEachRgb((x, y, rgb) => func(x, y, Color.rgb(rgb)))
  }

  rgbAtIndex (i) {
    return [this.array[i], this.array[i + 1], this.array[i + 2]]
  }

  getRgb (x, y) {
    return this.rgbAtIndex(this.getIndex(x, y))
  }

  getColor (x, y) {
    return Color.rgb(this.getRgb(x, y))
  }

  getHsv (x, y) {
    return this.getColor(x, y).hsv().object()
  }

  toGrey () {
    const grey = new GreyImage({ width: this.width, height: this.height })
    this.forEachRgb((x, y, rgb) => grey.setValueFromRgb(x, y, rgb))
    return grey
  }

  toLab () {
    const lab = new LabImage({ width: this.width, height: this.height })
    this.forEachRgb((x, y, rgb) => lab.setLabFromRgb(x, y, rgb))
    return lab
  }

  getColorPalette ({ ncolors = 10 }) {
    // hack to make ColorThief work with a canvas instead of an image
    this.canvas.naturalWidth = this.width
    this.canvas.naturalHeight = this.height
    const palette = new ColorThief().getPalette(this.canvas, ncolors)
    if (palette == null) {
      return [this.getColor(0, 0)]
    }
    return palette.map(rgb => Color.rgb(rgb))
  }

  getEnergy () {
    const width = this.width
    const height = this.height
    let totalEdginess = 0
    let totalV = 0
    this.forEachColor((x, y, color) => {
      const v = color.value()
      totalV += v
      totalEdginess += this.getEdginess(x, y)
    })

    return {
      intensity: totalV / (100 * width * height),
      edginess:  totalEdginess / (width * height)
    }
  }

  getEdginess (x, y) {
    const hsv = this.getHsv(x, y)
    return mean(
      (x > 0)           ? hsvDist(hsv, this.getHsv(x - 1, y)) : 0,
      (x < this.right)  ? hsvDist(hsv, this.getHsv(x + 1, y)) : 0,
      (y > 0)           ? hsvDist(hsv, this.getHsv(x, y - 1)) : 0,
      (y < this.bottom) ? hsvDist(hsv, this.getHsv(x, y + 1)) : 0
    )
  }
}

// as per https://stackoverflow.com/a/39113477/1056941
function hsvDist (c1, c2) {
  const h1 = c1.h * 2 * Math.PI / 360
  const h2 = c2.h * 2 * Math.PI / 360
  const s1 = c1.s / 100
  const s2 = c2.s / 100
  const v1 = c1.v / 100
  const v2 = c2.v / 100
  return Math.sqrt(sq(Math.sin(h1) * s1 * v1 - Math.sin(h2) * s2 * v2) + sq(Math.cos(h1) * s1 * v1 - Math.cos(h2) * s2 * v2) + sq(v1 - v2))
}
