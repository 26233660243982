function sixteenthsToSeconds (sixteenths) {
  return sixteenths * Tone.Time('16n').toSeconds() // depends on current global Tone Context bpm setting
}

function duration2sixteenths (duration) {
  const [bars, beats, sixteenths] = Tone.Time(duration).toBarsBeatsSixteenths().split(':').map(x => parseInt(x))
  return 16 * bars + 4 * beats + sixteenths
}

function timeSixteenths (measure, offset) {
  return 16 * measure + offset
}

class Note {
  constructor ({ measure, offset, degree, duration, vis, startBeam, chord }) {
    this.measure = measure
    this.offset = offset
    this.degree = degree
    this.duration = duration
    this.vis = vis
    this.startBeam = startBeam
    this.chord = chord
  }

  assignPitch (scale) {
    if (this.degree != null) this.pitch = scale.getPitch(this.degree)
    if (this.chord != null) this.chordName = scale.getChordName(this.chord)
  }

  get timeSixteenths () {
    return timeSixteenths(this.measure, this.offset)
  }

  get time () {
    return sixteenthsToSeconds(this.timeSixteenths)
  }

  get durationSixteenths () {
    return duration2sixteenths(this.duration)
  }

  get durationSeconds () {
    return sixteenthsToSeconds(this.durationSixteenths)
  }
}

class Track {
  constructor () {
    this._notes = []
    this._measure = 0
    this._offset = 0
  }

  addNote ({ degree, duration, vis, startBeam, chord }) {
    this.addChord({ degrees: [degree], duration, vis, startBeam, chord })
  }

  addChord ({ degrees, duration, vis, startBeam, chord }) {
    degrees.forEach(degree => this._notes.push(new Note({ measure: this._measure, offset: this._offset, degree, duration, vis, startBeam, chord })))

    const [measure, offset] = advanceOffset(this._measure, this._offset, duration)
    this._measure = measure
    this._offset = offset
  }

  insertNote ({ measure, offset, degree, duration, vis, startBeam }) {
    const degrees = [degree].flat()
    degrees.forEach(degree => this._notes.push(new Note({ measure, offset, degree, duration, vis, startBeam })))
    const [newMeasure, newOffset] = pairMax([this._measure, this._offset], advanceOffset(measure, offset, duration))
    this._measure = newMeasure
    this._offset = newOffset
  }

  addClosingRests () { // add rests to fill out measure
    switch (this._offset) {
    case 4:
      this.addNote({ duration: '4n', degree: null })
      this.addNote({ duration: '2n', degree: null })
      break
    case 8:
      this.addNote({ duration: '2n', degree: null })
      break
    case 12:
      this.addNote({ duration: '4n', degree: null })
      break
    }
  }

  get measureCount () {
    return this._measure + (this._offset > 0 ? 1 : 0)
  }

  get notes () {
    return this._notes
  }

  getNote (index) {
    return this._notes[index]
  }

  get lengthSixteenths () {
    return timeSixteenths(this._measure, this._offset)
  }

  get lengthSeconds () {
    return sixteenthsToSeconds(this.lengthSixteenths)
  }

  get noteCount () {
    return this._notes.length
  }
}

function advanceOffset (measure, offset, duration) {
  offset += duration2sixteenths(duration)
  while (offset >= 16) {
    offset -= 16
    measure += 1
  }
  return [measure, offset]
}

function pairMax ([a0, a1], [b0, b1]) {
  if (a0 > b0) {
    return [a0, a1]
  }
  if (a0 < b0) {
    return [b0, b1]
  }
  return [a0, Math.max(a1, b1)]
}

export class Song {
  constructor () {
    this.tracks = [new Track()]
  }

  addTrack () {
    const track = new Track()
    this.tracks.push(track)
    return track
  }

  get melody () {
    return this.tracks[0]
  }

  get measureCount () {
    return Math.max(...this.tracks.map(track => track.measureCount))
  }

  get lengthSeconds () {
    return Math.max(...this.tracks.map(track => track.lengthSeconds))
  }

  get noteCount () {
    return this.tracks.map(track => track.noteCount).reduce((sum, val) => sum + val, 0)
  }

  get repeat () {
    return this.melody.measureCount > 0 && (this.melody.measureCount * 2 <= this.measureCount)
  }

  assignPitches (scale) {
    this.tracks.forEach(track => track.notes.forEach(note => note.assignPitch(scale)))
  }
}
