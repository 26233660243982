import Color from '../../../_snowpack/pkg/color.js'

import { ImageBase } from './ImageBase.js'
import { mean } from '../util.js'

export class LabImage extends ImageBase {
  constructor ({ width, height, array, origin }) {
    super({ width, height, channels: 3, array, origin })
  }

  forEachLab (func) {
    this.forEachIndex((x, y, i) => func(x, y, this.getLabAtIndex(i)))
  }

  getLabAtIndex (i) {
    return [this.array[i], this.array[i + 1] - 128, this.array[i + 2] - 128]
  }

  setLabAtIndex (i, lab) {
    this.array[i + 0] = Math.round(lab[0])
    this.array[i + 1] = Math.round(lab[1]) + 128
    this.array[i + 2] = Math.round(lab[2]) + 128
  }

  getLab (x, y) {
    return this.getLabAtIndex(this.getIndex(x, y))
  }

  setLabFromRgb (x, y, rgb) {
    const c = Color.rgb(rgb)
    this.setLabAtIndex(this.getIndex(x, y), c.lab().array())
  }

  // not formal definition of energy, just something useful for us
  getEnergy () {
    const width = this.width
    const height = this.height
    let totalEdginess = 0
    let totalL = 0
    this.forEachLab((x, y, lab) => {
      totalL += lab[0]
      totalEdginess += this.getEdginess(x, y)
    })

    return {
      intensity: totalL / (100 * width * height),
      edginess:  totalEdginess / (width * height)
    }
  }

  getEdginess (x, y) {
    const lab = this.getLab(x, y)
    return mean(
      (x > 0)           ? deltaE(lab, this.getLab(x - 1, y)) : 0,
      (x < this.right)  ? deltaE(lab, this.getLab(x + 1, y)) : 0,
      (y > 0)           ? deltaE(lab, this.getLab(x, y - 1)) : 0,
      (y < this.bottom) ? deltaE(lab, this.getLab(x, y + 1)) : 0
    ) / 3
  }
}

function deltaE (labA, labB) {
  const deltaL = labA[0] - labB[0]
  const deltaA = labA[1] - labB[1]
  const deltaB = labA[2] - labB[2]
  const c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2])
  const c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2])
  const deltaC = c1 - c2
  let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC
  deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH)
  const sc = 1.0 + 0.045 * c1
  const sh = 1.0 + 0.015 * c1
  const deltaLKlsl = deltaL / (1.0)
  const deltaCkcsc = deltaC / (sc)
  const deltaHkhsh = deltaH / (sh)
  const i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh
  return i < 0 ? 0 : Math.sqrt(i)
}
