import { articulations, chordTriads } from './musicDefinitions/index.js'
import { getByAlpha, maxBy }          from './util.js'

function resolveDegree ({ gesture, chord }) {
  const { triadIndex, octave = 0, passing = 0 } = gesture
  const triad = chordTriads[chord]
  return triad[triadIndex] + passing + 7 * octave
}

export function accompany ({ progression, song, analysis }) {
  const track = song.addTrack()

  const articulation = getByAlpha(articulations, analysis.energy)

  const melodyDegree = song.melody.notes.reduce((sum, note) => sum + note.degree, 0) / song.melody.noteCount
  const chord = maxBy(progression, chord => chordTriads[chord][2])
  const accDegree = articulation.map(gesture => resolveDegree({ gesture, chord })).reduce((a, b) => a + b) / articulation.length

  const registerShift = accDegree >= melodyDegree ? Math.ceil((accDegree - melodyDegree) / 7) : 0
  // console.log({ articulation, registerShift })

  progression.forEach((chord, measure) => {
    if (measure < progression.length - 1) {
      articulation.forEach((gesture) => {
        const degree = resolveDegree({ gesture, chord }) - 7 * registerShift
        const { offset, duration } = gesture
        track.insertNote({ measure, offset, degree, duration })
      })
    } else {
      // hold chord for final measure
      const triad = chordTriads[chord]
      track.insertNote({ measure, offset: 0, degree: triad[0] - 7, duration: '4n' })
      track.insertNote({ measure, offset: 0, degree: triad[0],     duration: '4n' })
      track.insertNote({ measure, offset: 0, degree: triad[2],     duration: '4n' })
      track.insertNote({ measure, offset: 0, degree: triad[0] + 7, duration: '4n' })
      track.insertNote({ measure, offset: 0, degree: triad[1] + 7, duration: '4n' })
    }
  })
}
