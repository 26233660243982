import { clamp, stdev, uniq } from './util.js'

const LIGHTNESS_GAMMA = 0.9
const ENERGY_GAMMA = 2

export class Analysis {
  constructor ({ hue = 0, intensity = 0, variety = 0, edginess = 0 }) {
    this.hue = clamp(hue, 0, 359.999)
    this.intensity = clamp(intensity, 0, 0.999)
    this.variety = clamp(variety, 0, 0.999)
    this.edginess = clamp(edginess, 0, 0.999)
  }

  get warmth ()    { return Math.abs(180 - this.hue) / 180 }
  get coolness ()  { return 1 - this.warmth }
  get lightness () { return zeroPoint(0.1, 0.9 * Math.pow(this.intensity, LIGHTNESS_GAMMA) + 0.1 * this.coolness) }
  get energy ()    { return zeroPoint(0.1, 0.5 * Math.pow(this.intensity, ENERGY_GAMMA) + 0.5 * Math.max(this.variety, this.edginess)) }

  log (label) {
    if (label) console.log(label)
    console.log({
      hue:       this.hue,
      intensity: this.intensity,
      variety:   this.variety,
      edginess:  this.edginess
    })
    console.log({
      coolness:  this.coolness,
      lightness: this.lightness,
      energy:    this.energy
    })
  }

  static analyzeImage ({ image, ncolors = 10 }) {
    const palette = image.getColorPalette({ ncolors })
    const hues = uniq(palette.map(color => color.hue()))
    ncolors = hues.length
    // const weightedHue = hues.reduce((sum, hue, i) => sum + (ncolors - i) * hue, 0) / ((ncolors * (ncolors + 1)) / 2)
    const hueDeviation = stdev(hues) / 150
    const labImage = image.scaled({ scale: 0.25 }).toLab()
    const { intensity, edginess } = labImage.getEnergy()
    return new Analysis({ hue: hues[0], variety: hueDeviation, intensity, edginess })
  }
}

function zeroPoint (z, val) {
  return clamp((val - z) / (1 - z), 0, 1)
}
